import * as constants from './constants'

// POST COURSE
export function createCourse(params, saveGo, setFilter, isAdminClient) {
  return {
    type: constants.CREATE_COURSE,
    params,
    saveGo,
    setFilter,
    isAdminClient,
  }
}

export function createCourseSuccess(payload) {
  return {
    type: constants.CREATE_COURSE_SUCCESS,
    payload,
  }
}

export function createCourseFailed(error) {
  return {
    type: constants.CREATE_COURSE_FAILED,
    error,
  }
}

// POST SECTION
export function createSection(params, idCourse) {
  return {
    type: constants.CREATE_SECTION,
    params,
    idCourse,
  }
}

export function createSectionSuccess(payload) {
  return {
    type: constants.CREATE_SECTION_SUCCESS,
    payload,
  }
}

export function createSectionFailed(error) {
  return {
    type: constants.CREATE_SECTION_FAILED,
    error,
  }
}

// POST LESSON
export function createLesson(
  params,
  idCourse,
  resetForm,
  needToLoading = true
) {
  return {
    type: constants.CREATE_LESSON,
    params,
    idCourse,
    resetForm,
    needToLoading,
  }
}

export function createLessonSuccess(payload) {
  return {
    type: constants.CREATE_LESSON_SUCCESS,
    payload,
  }
}

export function createLessonFailed(error) {
  return {
    type: constants.CREATE_LESSON_FAILED,
    error,
  }
}

// POST QUIZ
export function createQuiz(params) {
  return {
    type: constants.CREATE_QUIZ,
    params,
  }
}

export function createQuizSuccess(payload) {
  return {
    type: constants.CREATE_QUIZ_SUCCESS,
    payload,
  }
}

export function createQuizFailed(error) {
  return {
    type: constants.CREATE_QUIZ_FAILED,
    error,
  }
}
// Load Tags
export function loadTags(params) {
  return {
    type: constants.LOAD_TAGS,
    params,
  }
}

export function loadTagsSuccess(payload) {
  return {
    type: constants.LOAD_TAGS_SUCCESS,
    payload,
  }
}

export function loadTagsFailed(error) {
  return {
    type: constants.LOAD_TAGS_FAILED,
    error,
  }
}

// Load Slide Tags
export function loadSlideTags(params) {
  return {
    type: constants.LOAD_SLIDE_TAGS,
    params,
  }
}

export function loadSlideTagsSuccess(payload) {
  return {
    type: constants.LOAD_SLIDE_TAGS_SUCCESS,
    payload,
  }
}

export function loadSlideTagsFailed(error) {
  return {
    type: constants.LOAD_SLIDE_TAGS_FAILED,
    error,
  }
}

// Load Course Type
export function LoadCourseType(params) {
  return {
    type: constants.LOAD_COURSE_TYPE,
    params,
  }
}

export function LoadCourseTypeSuccess(payload) {
  return {
    type: constants.LOAD_COURSE_TYPE_SUCCESS,
    payload,
  }
}

export function LoadCourseTypeFailed(error) {
  return {
    type: constants.LOAD_COURSE_TYPE_FAILED,
    error,
  }
}

// Set Selected Section
export function setSelectedSection(payload) {
  return {
    type: constants.SET_SELECTED_SECTION,
    payload,
  }
}
// CREATE ZOOM LINK
export function createZoomLink(params) {
  return {
    type: constants.CREATE_ZOOM_LINK,
    params,
  }
}

export function createZoomLinkSuccess(payload) {
  return {
    type: constants.CREATE_ZOOM_LINK_SUCCESS,
    payload,
  }
}

export function createZoomLinkFailed(error) {
  return {
    type: constants.CREATE_ZOOM_LINK_FAILED,
    error,
  }
}

// SAVE ZOOM LINK
export function saveZoomLink(params) {
  return {
    type: constants.SAVE_ZOOM_LINK,
    params,
  }
}

export function saveZoomLinkSuccess(payload) {
  return {
    type: constants.SAVE_ZOOM_LINK_SUCCESS,
    payload,
  }
}

export function saveZoomLinkFailed(error) {
  return {
    type: constants.SAVE_ZOOM_LINK_FAILED,
    error,
  }
}

// SET SHOW COURSE
export function setGlobalCourse(params, idCourse) {
  return {
    type: constants.SET_GLOBAL_COURSE,
    params,
    idCourse,
  }
}

export function setGlobalCourseSuccess(payload) {
  return {
    type: constants.SET_GLOBAL_COURSE_SUCCESS,
    payload,
  }
}

export function setGlobalCourseFailed(error) {
  return {
    type: constants.SET_GLOBAL_COURSE_FAILED,
    error,
  }
}

// SET SHOW COURSE
export function loadListUserAnswer(params) {
  return {
    type: constants.LOAD_LIST_USER_ANSWER,
    params,
  }
}

export function loadListUserAnswerSuccess(payload) {
  return {
    type: constants.LOAD_LIST_USER_ANSWER_SUCCESS,
    payload,
  }
}

export function loadListUserAnswerFailed(error) {
  return {
    type: constants.LOAD_LIST_USER_ANSWER_FAILED,
    error,
  }
}

// SET SHOW COURSE
export function setAnswerCompetent(params) {
  return {
    type: constants.SET_ANSWER_COMPETENT,
    params,
  }
}

export function setAnswerCompetentSuccess(payload) {
  return {
    type: constants.SET_ANSWER_COMPETENT_SUCCESS,
    payload,
  }
}

export function setAnswerCompetentFailed(error) {
  return {
    type: constants.SET_ANSWER_COMPETENT_FAILED,
    error,
  }
}

// SET SHOW COURSE
export function markAnswerCompetent(params) {
  return {
    type: constants.MARK_ANSWER_COMPETENT,
    params,
  }
}

export function markAnswerCompetentSuccess(payload) {
  return {
    type: constants.MARK_ANSWER_COMPETENT_SUCCESS,
    payload,
  }
}

export function markAnswerCompetentFailed(error) {
  return {
    type: constants.MARK_ANSWER_COMPETENT_FAILED,
    error,
  }
}

// CREATE ACCOUNT STRIPE
export function createAccountStripe(params) {
  return {
    type: constants.CREATE_ACCOUNT_STRIPE,
    params,
  }
}

export function createAccountStripeSuccess(payload) {
  return {
    type: constants.CREATE_ACCOUNT_STRIPE_SUCCESS,
    payload,
  }
}

export function createAccountStripeFailed(error) {
  return {
    type: constants.CREATE_ACCOUNT_STRIPE_FAILED,
    error,
  }
}

// GET ACCOUNT STRIPE
export function getAccountStripe(params) {
  return {
    type: constants.GET_ACCOUNT_STRIPE,
    params,
  }
}

export function getAccountStripeSuccess(payload) {
  return {
    type: constants.GET_ACCOUNT_STRIPE_SUCCESS,
    payload,
  }
}

export function getAccountStripeFailed(error) {
  return {
    type: constants.GET_ACCOUNT_STRIPE_FAILED,
    error,
  }
}

// ADD SCHEDULE
export function addSchedule(params, page, handleResetField) {
  return {
    type: constants.ADD_SCHEDULE,
    params,
    page,
    handleResetField,
  }
}

export function addScheduleSuccess(payload) {
  return {
    type: constants.ADD_SCHEDULE_SUCCESS,
    payload,
  }
}

export function addScheduleFailed(error) {
  return {
    type: constants.ADD_SCHEDULE_FAILED,
    error,
  }
}

// ADD SCHEDULE
export function getSchedules(
  params,
  isAdmin,
  page,
  needToLoadSize = true,
  needToLoadAll,
  isGetDeleted = false
) {
  return {
    type: constants.GET_SCHEDULE,
    params,
    isAdmin,
    page,
    needToLoadSize,
    needToLoadAll,
    isGetDeleted,
  }
}

export function getSchedulesSuccess(payload) {
  return {
    type: constants.GET_SCHEDULE_SUCCESS,
    payload,
  }
}

export function getSchedulesFailed(error) {
  return {
    type: constants.GET_SCHEDULE_FAILED,
    error,
  }
}

// DELETE SCHEDULE
export function deleteSchedule(params, page) {
  return {
    type: constants.DELETE_SCHEDULE,
    params,
    page,
  }
}
// DELETE COURSE
export function deleteCourse(params) {
  return {
    type: constants.DELETE_COURSE,
    params,
  }
}

export function deleteScheduleSuccess(payload) {
  return {
    type: constants.DELETE_SCHEDULE_SUCCESS,
    payload,
  }
}

export function deleteCourseSuccess(payload) {
  return {
    type: constants.DELETE_COURSE_SUCCESS,
    payload,
  }
}

export function deleteScheduleFailed(error) {
  return {
    type: constants.DELETE_SCHEDULE_FAILED,
    error,
  }
}

export function deleteCourseFailed(error) {
  return {
    type: constants.DELETE_COURSE_FAILED,
    error,
  }
}
//
export function deleteLesson(params) {
  return {
    type: constants.DELETE_LESSON,
    params,
  }
}

export function deleteLessonSuccess(payload) {
  return {
    type: constants.DELETE_LESSON_SUCCESS,
    payload,
  }
}

export function deleteLessonFailed(error) {
  return {
    type: constants.DELETE_LESSON_FAILED,
    error,
  }
}

// load list learning out come
export function loadListLearningOutcome(params) {
  return {
    type: constants.LOAD_LIST_LEARNING_OUTCOME,
    params,
  }
}

export function loadListLearningOutcomeSuccess(payload) {
  return {
    type: constants.LOAD_LIST_LEARNING_OUTCOME_SUCCESS,
    payload,
  }
}

export function loadListLearningOutcomeFailed(error) {
  return {
    type: constants.LOAD_LIST_LEARNING_OUTCOME_FAILED,
    error,
  }
}

// on create learning out come
export function createLearningOutcome(
  params,
  needToLoadSlides = false,
  selectedSchedule,
  idCourse
) {
  return {
    type: constants.CREATE_LEARNING_OUTCOME,
    params,
    needToLoadSlides,
    selectedSchedule,
    idCourse,
  }
}

export function createLearningOutcomeSuccess(payload) {
  return {
    type: constants.CREATE_LEARNING_OUTCOME_SUCCESS,
    payload,
  }
}

export function createLearningOutcomeFailed(error) {
  return {
    type: constants.CREATE_LEARNING_OUTCOME_FAILED,
    error,
  }
}

// on delete learning out come
export function deleteLearningOutcome(params) {
  return {
    type: constants.DELETE_LEARNING_OUTCOME,
    params,
  }
}

export function deleteLearningOutcomeSuccess(payload) {
  return {
    type: constants.DELETE_LEARNING_OUTCOME_SUCCESS,
    payload,
  }
}

export function deleteLearningOutcomeFailed(error) {
  return {
    type: constants.DELETE_LEARNING_OUTCOME_FAILED,
    error,
  }
}

// load list assessment
export function loadAssessment(params) {
  return {
    type: constants.LOAD_ASSESSMENT,
    params,
  }
}

export function loadAssessmentSuccess(payload) {
  return {
    type: constants.LOAD_ASSESSMENT_SUCCESS,
    payload,
  }
}

export function loadAssessmentFailed(error) {
  return {
    type: constants.LOAD_ASSESSMENT_FAILED,
    error,
  }
}

// create learning out come
export function createAssessment(params, question) {
  return {
    type: constants.CREATE_ASSESSMENT,
    params,
    question,
  }
}

export function createAssessmentSuccess(payload) {
  return {
    type: constants.CREATE_ASSESSMENT_SUCCESS,
    payload,
  }
}

export function createAssessmentFailed(error) {
  return {
    type: constants.CREATE_ASSESSMENT_FAILED,
    error,
  }
}

// on delete assessment
export function deleteAssessment(params) {
  return {
    type: constants.DELETE_ASSESSMENT,
    params,
  }
}

export function deleteAssessmentSuccess(payload) {
  return {
    type: constants.DELETE_ASSESSMENT_SUCCESS,
    payload,
  }
}

export function deleteAssessmentFailed(error) {
  return {
    type: constants.DELETE_ASSESSMENT_FAILED,
    error,
  }
}

// on delete assessment
export function deleteCustomOutcome(params) {
  return {
    type: constants.DELETE_CUSTOM_OUTCOME,
    params,
  }
}

export function deleteCustomOutcomeSuccess(payload) {
  return {
    type: constants.DELETE_CUSTOM_OUTCOME_SUCCESS,
    payload,
  }
}

export function deleteCustomOutcomeFailed(error) {
  return {
    type: constants.DELETE_CUSTOM_OUTCOME_FAILED,
    error,
  }
}

// on load attachment
export function loadAttachment(params) {
  return {
    type: constants.LOAD_ATTACHMENT,
    params,
  }
}

export function loadAttachmentSuccess(payload) {
  return {
    type: constants.LOAD_ATTACHMENT_SUCCESS,
    payload,
  }
}

export function loadAttachmentFailed(error) {
  return {
    type: constants.LOAD_ATTACHMENT_FAILED,
    error,
  }
}

// on create attachment
export function createAttachment(params) {
  return {
    type: constants.CREATE_ATTACHMENT,
    params,
  }
}

export function createAttachmentSuccess(payload) {
  return {
    type: constants.CREATE_ATTACHMENT_SUCCESS,
    payload,
  }
}

export function createAttachmentFailed(error) {
  return {
    type: constants.CREATE_ATTACHMENT_FAILED,
    error,
  }
}

// on delete attachment
export function deleteAttachment(params, needToLoad = false) {
  return {
    type: constants.DELETE_ATTACHMENT,
    params,
    needToLoad,
  }
}

export function deleteAttachmentSuccess(payload) {
  return {
    type: constants.DELETE_ATTACHMENT_SUCCESS,
    payload,
  }
}

export function deleteAttachmentFailed(error) {
  return {
    type: constants.DELETE_ATTACHMENT_FAILED,
    error,
  }
}

// on delete attachment
export function loadZooms(params) {
  return {
    type: constants.LOAD_ZOOMS,
    params,
  }
}

export function loadZoomsSuccess(payload) {
  return {
    type: constants.LOAD_ZOOMS_SUCCESS,
    payload,
  }
}

export function loadZoomsFailed(error) {
  return {
    type: constants.LOAD_ZOOMS_FAILED,
    error,
  }
}

// on delete attachment
export function deleteScheduleItem(params) {
  return {
    type: constants.DELETE_SCHEDULE_ITEM,
    params,
  }
}

export function deleteScheduleItemSuccess(payload) {
  return {
    type: constants.DELETE_SCHEDULE_ITEM_SUCCESS,
    payload,
  }
}

export function deleteScheduleItemFailed(error) {
  return {
    type: constants.DELETE_SCHEDULE_ITEM_FAILED,
    error,
  }
}

// on delete attachment
export function loadListUser(params) {
  return {
    type: constants.LOAD_LIST_USER,
    params,
  }
}

export function loadListUserSuccess(payload) {
  return {
    type: constants.LOAD_LIST_USER_SUCCESS,
    payload,
  }
}

export function loadListUserFailed(error) {
  return {
    type: constants.LOAD_LIST_USER_FAILED,
    error,
  }
}

// on get user info
export function getUserData(
  params,
  needToUpdate = false,
  needToIgnore = false
) {
  return {
    type: constants.GET_USER_DATA,
    params,
    needToUpdate,
    needToIgnore,
  }
}

export function getUserDataSuccess(payload) {
  return {
    type: constants.GET_USER_DATA_SUCCESS,
    payload,
  }
}

export function getUserDataFailed(error) {
  return {
    type: constants.GET_USER_DATA_FAILED,
    error,
  }
}

// on get user info
export function getUserProfile(params) {
  return {
    type: constants.GET_USER_PROFILE,
    params,
  }
}

export function getUserProfileSuccess(payload) {
  return {
    type: constants.GET_USER_PROFILE_SUCCESS,
    payload,
  }
}

export function getUserProfileFailed(error) {
  return {
    type: constants.GET_USER_PROFILE_FAILED,
    error,
  }
}

// on delete attachment
export function getListScheduleByEmail(params) {
  return {
    type: constants.GET_LIST_SCHEDULE_BY_EMAIL,
    params,
  }
}

export function getListScheduleByEmailSuccess(payload) {
  return {
    type: constants.GET_LIST_SCHEDULE_BY_EMAIL_SUCCESS,
    payload,
  }
}

export function getListScheduleByEmailFailed(error) {
  return {
    type: constants.GET_LIST_SCHEDULE_BY_EMAIL_FAILED,
    error,
  }
}

// on delete attachment
export function addScheduleToUserEmail(params) {
  return {
    type: constants.ADD_SCHEDULE_TO_USER_EMAIL,
    params,
  }
}

export function addScheduleToUserEmailSuccess(payload) {
  return {
    type: constants.ADD_SCHEDULE_TO_USER_EMAIL_SUCCESS,
    payload,
  }
}

export function addScheduleToUserEmailFailed(error) {
  return {
    type: constants.ADD_SCHEDULE_TO_USER_EMAIL_FAILED,
    error,
  }
}

// on delete attachment
export function deleteScheduleOfUser(params) {
  return {
    type: constants.DELETE_SCHEDULE_OF_USER,
    params,
  }
}

export function deleteScheduleOfUserSuccess(payload) {
  return {
    type: constants.DELETE_SCHEDULE_OF_USER_SUCCESS,
    payload,
  }
}

export function deleteScheduleOfUserFailed(error) {
  return {
    type: constants.DELETE_SCHEDULE_OF_USER_FAILED,
    error,
  }
}

// SET REMARKS ANSWER
export function setRemarksAnswer(params) {
  return {
    type: constants.SET_REMARKS_ANSWER,
    params,
  }
}

export function setRemarksAnswerSuccess(payload) {
  return {
    type: constants.SET_REMARKS_ANSWER_SUCCESS,
    payload,
  }
}

export function setRemarksAnswerFailed(error) {
  return {
    type: constants.SET_REMARKS_ANSWER_FAILED,
    error,
  }
}

// SET REMARKS ANSWER
export function getUserByCourseAndSchedule(params) {
  return {
    type: constants.GET_USER_BY_COURSE_AND_SCHEDULE,
    params,
  }
}

export function getUserByCourseAndScheduleSuccess(payload) {
  return {
    type: constants.GET_USER_BY_COURSE_AND_SCHEDULE_SUCCESS,
    payload,
  }
}

export function getUserByCourseAndScheduleFailed(error) {
  return {
    type: constants.GET_USER_BY_COURSE_AND_SCHEDULE_FAILED,
    error,
  }
}

// SET REMARKS ANSWER
export function getLearningOutComeByUser(params) {
  return {
    type: constants.GET_LEARNING_OUTCOME_BY_USER,
    params,
  }
}

export function getLearningOutComeByUserSuccess(payload) {
  return {
    type: constants.GET_LEARNING_OUTCOME_BY_USER_SUCCESS,
    payload,
  }
}

export function getLearningOutComeByUserFailed(error) {
  return {
    type: constants.GET_LEARNING_OUTCOME_BY_USER_FAILED,
    error,
  }
}

// SET_COMPETENT_LEARNINGOUTCOME
export function setCompetentLearningOutcome(params) {
  return {
    type: constants.SET_COMPETENT_LEARNING_OUTCOME,
    params,
  }
}

export function setCompetentLearningOutcomeSuccess(payload) {
  return {
    type: constants.SET_COMPETENT_LEARNING_OUTCOME_SUCCESS,
    payload,
  }
}

export function setCompetentLearningOutcomeFailed(error) {
  return {
    type: constants.SET_COMPETENT_LEARNING_OUTCOME_FAILED,
    error,
  }
}

// LIST SET OUTCOME STUDENTS
export function getListSetOutcomeStudents(params) {
  return {
    type: constants.GET_LIST_SET_OUTCOME_STUDENTS,
    params,
  }
}

export function getListSetOutcomeStudentsSuccess(payload) {
  return {
    type: constants.GET_LIST_SET_OUTCOME_STUDENTS_SUCCESS,
    payload,
  }
}

export function getListSetOutcomeStudentsFailed(error) {
  return {
    type: constants.GET_LIST_SET_OUTCOME_STUDENTS_FAILED,
    error,
  }
}

// Add Custom Outcome student
export function addOutcomeCourse(params) {
  return {
    type: constants.ADD_OUTCOME_COURSE,
    params,
  }
}

export function addOutcomeCourseSuccess(payload) {
  return {
    type: constants.ADD_OUTCOME_COURSE_SUCCESS,
    payload,
  }
}

export function addOutcomeCourseFailed(error) {
  return {
    type: constants.ADD_OUTCOME_COURSE_FAILED,
    error,
  }
}

// Get List Custom Outcome student
export function getListOutcomeStudent(params) {
  return {
    type: constants.GET_LIST_OUTCOME_STUDENT,
    params,
  }
}

export function getListOutcomeStudentSuccess(payload) {
  return {
    type: constants.GET_LIST_OUTCOME_STUDENT_SUCCESS,
    payload,
  }
}

export function getListOutcomeStudentFailed(error) {
  return {
    type: constants.GET_LIST_OUTCOME_STUDENT_FAILED,
    error,
  }
}

// Get List Custom Outcome student
export function setListOutcomeCustom(params) {
  return {
    type: constants.SET_LIST_OUTCOME_CUSTOM,
    params,
  }
}

export function setListOutcomeCustomSuccess(payload) {
  return {
    type: constants.SET_LIST_OUTCOME_CUSTOM_SUCCESS,
    payload,
  }
}

export function setListOutcomeCustomFailed(error) {
  return {
    type: constants.SET_LIST_OUTCOME_CUSTOM_FAILED,
    error,
  }
}

// Get List Custom Outcome student
export function getListSetOutcomeCustom(params) {
  return {
    type: constants.GET_LIST_SET_OUTCOME_CUSTOM,
    params,
  }
}

export function getListSetOutcomeCustomSuccess(payload) {
  return {
    type: constants.GET_LIST_SET_OUTCOME_CUSTOM_SUCCESS,
    payload,
  }
}

export function getListSetOutcomeCustomFailed(error) {
  return {
    type: constants.GET_LIST_SET_OUTCOME_CUSTOM_FAILED,
    error,
  }
}

export function loadPaymentStatus(params) {
  return {
    type: constants.LOAD_PAYMENT_STATUS,
    params,
  }
}

export function loadPaymentStatusSuccess(payload) {
  return {
    type: constants.LOAD_PAYMENT_STATUS_SUCCESS,
    payload,
  }
}

export function loadPaymentStatusFailed(error) {
  return {
    type: constants.LOAD_PAYMENT_STATUS_FAILED,
    error,
  }
}

//
export function setQuestions(payload) {
  return {
    type: constants.SET_QUESTIONS,
    payload,
  }
}
