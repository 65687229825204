import React, { useEffect, useState, useMemo } from 'react'
import { Modal, Select } from 'antd'
import { get, isEmpty } from 'lodash'
import BtnPrimary from 'component/BtnPrimary'
import {
  hideExportQuizReportPDF,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { useSelector } from 'react-redux'
import {
  mapListCourseToDropDown,
  mapScheduleToDropDown,
  encodedSlideByQuiz,
} from 'utils/helper'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { loadSlides } from 'container/Home/actions'
import { makeSelectSlides } from 'container/Home/selectors'
import { getUserInfo } from 'utils/request'
import useLoadCourses from '../../../../hooks/useLoadCourses'
import jsPDF from 'jspdf'
import { makeSelectDomain } from '../../../Home/selectors'

const ExportQuizReportPDF = ({
  dispatch,
  courseSelected,
  showExportQuizReportPDF,
}) => {
  const slides = useSelector(makeSelectSlides())

  const slidesRemoveSection = useMemo(() => {
    const arr = []
    Array.isArray(slides) &&
      slides.forEach((slide) => {
        arr.push(...slide.items?.filter((item) => item.questions?.length))
      })
    return arr
  }, [slides])

  const topics = useMemo(() => {
    const data = slides?.reduce((prev, curr) => {
      if (
        curr?.questions?.length ||
        curr?.items?.find((item) => item.questions?.length)
      ) {
        return [...prev, curr]
      }
      return prev
    }, [])
    return data
  }, [slides])

  const slideIsQuiz = useMemo(
    () =>
      slidesRemoveSection &&
      slidesRemoveSection?.filter((i) => i?.questions?.length),
    [slidesRemoveSection]
  )
  const courses = useLoadCourses()
  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)

  const course = useMemo(() => {
    if (selectedCourse?.value && courses) {
      return courses.find((course) => course.id === selectedCourse.value)
    }
  }, [selectedCourse?.value, courses])

  const requireSchedule = get(course, 'is_schedule', false)
  const channelID = get(selectedCourse, 'value', '')
  const scheduleID = get(selectedSchedule, 'value', 0)
  const domain = useSelector(makeSelectDomain())
  useEffect(() => {
    if (courseSelected) {
      setSelectedCourse({
        value: courseSelected.id,
        label: courseSelected.name,
      })
    }
  }, [courseSelected])

  useEffect(() => {
    if (channelID) {
      dispatch(loadSlides(channelID, false, 0))
    }
  }, [channelID])

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getSchedules(selectedCourse.value, true, null, false, true))
    }
  }, [selectedCourse])

  const handleCancel = () => dispatch(hideExportQuizReportPDF())

  const handleExport = async (isExportAll = false) => {
    if (selectedCourse) {
      let requestURL = `${config.baseUrl}/api/lms/courses/channels/${
        selectedCourse.value
      }/partners?schedule_id=${
        !isExportAll ? get(selectedSchedule, 'value', 0) : 0
      }&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()

        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          const requestsUserQuizPassRate = []
          for (let i = 0; i < data.data.length; i++) {
            const userId = get(data.data, `[${i}].user.id`)
            requests.push(getUserInfo(userId, course?.website_id))
            requestsUserQuizPassRate.push(fetchAllAnswerByUser(userId))
          }

          const usersInfo = await Promise.all(requests)
          const userQuizPassRate = await Promise.all(requestsUserQuizPassRate)
          generatePDF(data.data, usersInfo, userQuizPassRate)
        }

        handleCancel()
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const fetchAllAnswerByUser = async (userId) => {
    const encodedSlideId = encodedSlideByQuiz(slidesRemoveSection)
    const scheduleId = selectedSchedule?.value
      ? `schedule_id=${selectedSchedule?.value}`
      : ''

    try {
      const res = await fetch(
        `${config.baseUrl}/api/users/${userId}/slides/answers?${scheduleId}${encodedSlideId}&order_by=id%20asc&per_page=1000&ignore_per_page=true`,
        {
          method: 'GET',
        }
      )
      const data = await res.json()

      return data.data
    } catch (error) {
      console.log('err', error)
    }
  }

  const generatePDF = (data, usersInfo, userQuizPassRate) => {
    const doc = new jsPDF()
    const marginX = 15

    data.forEach((candidate, index) => {
      const candidateName =
        get(usersInfo, `[${index}].name`, '') ||
        get(candidate, `user.partner.name`, '')

      const userEmail =
        get(usersInfo, `[${index}].email`, '') ||
        get(candidate, `user.partner.email`, '')
      const nric =
        get(usersInfo, `[${index}].passport`, '')?.slice(-4) ||
        get(candidate, `user.passport`, '')?.slice(-4)
      const classCode = get(candidate, 'schedule.class_code', '')

      let quizPassed = []
      const tableData =
        topics &&
        topics?.reduce((prev, curr, idx) => {
          if (curr) {
            const quizOfLesson = slideIsQuiz[idx]
            const markQuizResult =
              Array.isArray(userQuizPassRate[index]) &&
              userQuizPassRate[index].find(
                (item) => item.slide_id === quizOfLesson?.id
              )

            const totalPassed =
              get(markQuizResult, 'answer.total_passed', 0) >
              quizOfLesson?.questions?.length
                ? quizOfLesson?.questions?.length
                : get(markQuizResult, 'answer.total_passed', 0)
            const quizPassRate = quizOfLesson?.quiz_pass_rate || 0
            const percentGrade =
              quizOfLesson &&
              totalPassed !== 0 &&
              (totalPassed / quizOfLesson?.questions?.length) * 100

            const isPassed =
              percentGrade >= 0 && totalPassed !== 0
                ? percentGrade >= quizPassRate
                : false
            quizPassed.push(isPassed)
            return [
              ...prev,
              {
                module: curr.name,
                marks: totalPassed + '/' + quizOfLesson?.questions?.length,
                score: Math.round(percentGrade) + '%' || '0%',
              },
            ]
          }
          return prev
        }, [])

      doc.setFontSize(14)
      doc.text(domain?.name, marginX, 20)
      doc.setFontSize(12)
      doc.text(`${courseSelected?.name} Course Report`, marginX, 30)
      doc.text(
        'This document serves as a record of evidence gathered for the completion and competency',
        marginX,
        40
      )
      doc.text('assessment of the following candidate.', marginX, 45)

      doc.autoTable({
        margin: { left: marginX },
        startY: 55,
        startX: marginX,
        theme: 'plain',

        styles: {},
        bodyStyles: {
          lineWidth: 0.4,
          lineColor: 12,
        },
        body: [
          [
            `Candidate Name (as in NRIC): ${candidateName}`,
            `Last 4 digit of NRIC: ${nric}`,
          ],
          [`Email: ${userEmail}`, `Class Schedule: ${classCode}`],

          [
            `Status of Completion: ${
              quizPassed?.length > 0
                ? quizPassed.some((item) => !item)
                  ? 'Incomplete'
                  : 'Complete'
                : 'Incomplete'
            }`,
            `Overall Assessment Result:  ${
              quizPassed?.length > 0
                ? quizPassed.some((item) => !item)
                  ? 'Fail'
                  : 'Pass'
                : 'Fail'
            }`,
          ],
        ].filter((x) => x),
      })

      doc.autoTable({
        margin: { left: marginX, bottom: 100 },
        startY: 80,
        showHead: 'firstPage',
        theme: 'grid',
        rowPageBreak: 'avoid',

        bodyStyles: {
          lineWidth: 0.4,
          lineColor: 12,
        },
        headStyles: {
          lineWidth: 0.4,
          lineColor: 12,
          valign: 'middle',
          halign: 'center',
          fillColor: [217, 217, 217],
          textColor: [32, 32, 32],
        },
        columnStyles: {
          0: {},
          1: { halign: 'center' },
          2: { halign: 'center' },
        },

        head: [
          [
            {
              content: 'Module',
            },
            { content: 'Assessment Marks' },
            { content: 'Score Percentage' },
          ],
        ],
        body: tableData.map((item) => [item.module, item.marks, item.score]),
      })

      if (index < data.length - 1) {
        doc.addPage()
      }
    })
    doc.save(`${courseSelected?.name.replace(/ /g, '_')}_Course_Report.pdf`)
  }

  return (
    <Modal
      visible={showExportQuizReportPDF}
      footer={null}
      onCancel={handleCancel}
    >
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Export Quiz Report (PDF)
          <BtnPrimary
            name="Export All"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginLeft: 20,
            }}
            handleClick={() => handleExport(true)}
          />
        </div>
        <div className="sc-modal__label">Courses</div>
        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(_, course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>
            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}

        {(selectedCourse && selectedSchedule) || !requireSchedule ? (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={() => handleExport(false)}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export default ExportQuizReportPDF
