import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useLocation, Link } from 'react-router-dom'
import { parse } from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import images from 'Themes/Images'
import InputTypePassword from 'component/InputTypePassword'
import { Login, LoginFailed } from '../../actions'
import { makeSelectError } from '../../selectos'
import { ERROR_MESSAGE } from 'utils/constants'
import Input from 'component/Input'
import ForgotPasswordBtn from 'component/ForgotPasswordBtn'
import './styles.scss'
import { makeSelectDomain } from '../../../Home/selectors'
import { get } from 'lodash'
import { DOMAIN } from '../../../../routes'

const StudentLoginSchema = Yup.object().shape({
  password: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().email().required(ERROR_MESSAGE),
})

const SchoolLoginSchema = Yup.object().shape({
  password: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().required(ERROR_MESSAGE),
})

const LoginForm = ({ superAdmin, isMobile }) => {
  const error = useSelector(makeSelectError())
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { student } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION
  const isWFADomain = get(domain, 'domain', '') === DOMAIN.WFA
  const isUnicornDomain = get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN

  useEffect(() => {
    dispatch(LoginFailed(''))
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: student ? StudentLoginSchema : SchoolLoginSchema,
    onSubmit: (values) => {
      dispatch(Login({ values }))
    },
  })
  const handleClearForm = () => {
    formik.resetForm()
  }

  const isValid = formik.values.password && formik.values.email
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="login-form">
        <div className="login-form__wrap">
          {!superAdmin && (
            <div className="login-form__switch">
              <div
                className={`login-form__students ${
                  student && 'login-form__switch-active'
                }`}
                onClick={handleClearForm}
              >
                <Link to="/auth?login=true&student=true">LEARNER LOGIN</Link>
              </div>
              <div
                className={`login-form__schools ${
                  !student && 'login-form__switch-active'
                }`}
                onClick={handleClearForm}
              >
                <Link to="/auth?login=true">SCHOOL LOGIN</Link>
              </div>
            </div>
          )}

          <div className="login-form__text">
            {superAdmin ? 'Super Admin log in' : 'Welcome back!'}
          </div>
          {error && <div className="login-form__error">{error}</div>}
          <form
            onSubmit={formik.handleSubmit}
            style={{ marginBottom: !isMobile ? 56 : 0 }}
          >
            <div className="form__field">
              <Input
                label="EMAIL ADDRESS"
                name="email"
                style={{ padding: 12 }}
                className="mg-small"
                placeHolder="Enter your email address"
                value={formik.values.email}
                formik={formik}
                error={error}
                onChange={(e) => {
                  formik.setFieldValue(
                    'email',
                    e.currentTarget.value.trim().toLowerCase()
                  )
                }}
              />
            </div>
            <div className="form__field" style={{ marginBottom: 8 }}>
              <div className="form__box">
                <label htmlFor="password" className="form__label">
                  Password
                </label>
                <ForgotPasswordBtn />
              </div>

              <InputTypePassword
                placeholder={'Enter your password'}
                onChange={(e) => {
                  formik.setFieldValue('password', e.currentTarget.value.trim())
                }}
                name="password"
                className="form__input"
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="form__error">{formik.errors.password}</div>
              )}
            </div>
            <div
              className="login-form__register__action"
              style={{ fontSize: 12, marginBottom: 8, textAlign: 'center' }}
            >
              Contact{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                hello@hypeschools.com
              </span>{' '}
              if you have problems logging in
            </div>
            <button
              type="submit"
              className={`primary-btn ${!isValid && 'primary-btn-disable'}`}
              style={{ width: '100%' }}
            >
              <span>Login</span>
              {isValid ? (
                <img src={images.course.arrow_right_active} alt="" />
              ) : (
                <img src={images.course.arrow_right} alt="" />
              )}
            </button>
          </form>
        </div>
        {!superAdmin && !isBellDomain && !isWFADomain && !isUnicornDomain && (
          <div className="login-form__register">
            <div className="login-form__register__wrap">
              <div className="login-form__register__question">
                Don’t have an account?
              </div>
              <div className="login-form__register__action">
                Create an account today and start learning.
              </div>
              <Link to="/auth?signup=true">
                <button className="quaternary-btn">REGISTER</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default LoginForm
