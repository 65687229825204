import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'
import {
  makeSelectListCourse,
  makeSelectDomain,
} from 'container/Home/selectors'
import TableEnrollment from '../TableEnrollment'
import { getCourseEnrollment } from 'utils/helper'
import { loadListCourse } from 'container/Home/actions'
import { get } from 'lodash'
import { showDetailsRegistration } from 'container/Modal/actions'
import { Space } from 'antd'
import BtnPrimary from '../../../../component/BtnPrimary'
import TransferQuizModal from './components/TransferQuiz/TransferQuizModal'
import { DOMAIN } from '../../../../routes'

const Enrollment = ({
  userData,
  userProfile,
  dispatch,
  setIsUpdateAllInvoices,
  setCourseAllInvoices,
}) => {
  const listCourse = useSelector(makeSelectListCourse())
  const domain = useSelector(makeSelectDomain())
  const listCourseEnrollment = getCourseEnrollment(listCourse)
  const [visibleModal, setVisibleModal] = useState(false)
  const { search } = useLocation()
  const { course_id } = parse(search)

  const isWFADomain = domain && domain.domain === DOMAIN.WFA

  useEffect(() => {
    if (course_id && listCourse) {
      const course = listCourseEnrollment.find(
        (item) => item.id === parseInt(course_id)
      )
      const partner = get(course, 'partners[0]', null)
      if (course) {
        dispatch(
          showDetailsRegistration({ ...partner, ...userData, course: course })
        )
      }
    }
  }, [course_id, listCourseEnrollment])

  useEffect(() => {
    if (userData) {
      dispatch(
        loadListCourse({
          partner: get(userData, 'partner_id'),
          admin: true,
          filter: 'sequence',
          newFilter: 'per_page=1000&ignore_per_page=true&include_delete=true',
        })
      )
    }
  }, [userData])

  return (
    <div>
      <Space>
        <h1 className="u-manage__heading">Enrolment</h1>

        <BtnPrimary
          name="Transfer Quiz"
          style={{
            fontWeight: 'bold',
            color: '#fff',
            marginBottom: 15,
            marginLeft: 30,
          }}
          type="button"
          handleClick={() => setVisibleModal(true)}
        />
      </Space>

      {listCourse && (
        <TableEnrollment
          listCourseEnrollment={listCourseEnrollment}
          userData={userData}
          userProfile={userProfile}
          domain={domain}
          setIsUpdateAllInvoices={setIsUpdateAllInvoices}
          setCourseAllInvoices={setCourseAllInvoices}
        />
      )}
      <TransferQuizModal
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        listCourses={listCourseEnrollment}
        userID={userProfile?.user_id || userData?.id}
      />
    </div>
  )
}

export default Enrollment
