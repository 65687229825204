import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table } from 'antd'
import { get } from 'lodash'

import Remark from '../Remark'
import images from 'Themes/Images'
import {
  setCompetentLearningOutcome,
  setListOutcomeCustom,
} from '../../../../actions'
import EditOutcome from '../EditOutcome'
import EditAssessment from '../EditAssessment'
import EditType from '../EditType'

import { showDeleteLearningOutcome } from 'container/Modal/actions'
import './styles.scss'
import { showError } from '../../../../../../utils/notification'

const TableLearningOutCome = ({
  id,
  currentUser,
  learningOutcome,
  listOutcome = [],
  selectedSchedule,
  learningOutcomeUser,
  listSetOutcomeCustom,
  listCustomOutcomeUser,
  remarks,
  setRemarks,
  isUnicornOrBell = false,
  isUnicornDomainOnly = false,
  isBellDomainOnly = false,
}) => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState(null)
  const uid = get(currentUser, 'user.id', '')
  const isCustom =
    Array.isArray(listCustomOutcomeUser) && listCustomOutcomeUser.length > 0

  const columnsTable = [
    {
      title: 'Learning Outcome',
      width: '30%',
      render: (record) => {
        const specificOutCome = listOutcome.find(
          (item) =>
            item.id === record.assessment_criteria.learning_outcome_id &&
            !record.is_custom
        )
        const matchRow = selectedRow && record.id === selectedRow.id

        return (
          <EditOutcome
            record={record}
            isCustom={isCustom}
            dispatch={dispatch}
            matchRow={matchRow}
            setSelectedRow={setSelectedRow}
            specificOutCome={specificOutCome}
          />
        )
      },
    },
    {
      title: 'Assessment Criteria',
      width: '30%',
      render: (record) => {
        const matchRow = selectedRow && record.id === selectedRow.id

        return (
          <EditAssessment
            record={record}
            dispatch={dispatch}
            matchRow={matchRow}
            setSelectedRow={setSelectedRow}
          />
        )
      },
    },
    {
      title: 'Type',
      width: '18%',
      render: (record) => {
        const isCustom = record.is_custom || false
        const specificOutcome = learningOutcomeUser.find(
          (item) =>
            item.learning_outcome_id === record.id &&
            item.user_id === uid &&
            !record.is_custom
        )

        const matchRow = selectedRow && record.id === selectedRow.id

        return (
          <EditType
            isCustom={isCustom}
            currentUser={currentUser}
            record={record}
            dispatch={dispatch}
            matchRow={matchRow}
            setSelectedRow={setSelectedRow}
            specificOutcome={specificOutcome}
            id={id}
            selectedSchedule={selectedSchedule}
            isUnicornDomainOnly={isUnicornDomainOnly}
            isBellDomainOnly={isBellDomainOnly}
          />
        )
      },
    },
    {
      title: 'Remarks',
      width: '25%',
      render: (record, i, index) => {
        const specificOutcome = learningOutcomeUser.find(
          (item) =>
            item.learning_outcome_id === record.id &&
            item.user_id === uid &&
            !record.is_custom
        )

        const specificCustom = listSetOutcomeCustom.find(
          (item) =>
            record.id === item.learning_outcome_channel_custom_id &&
            item.user_id === uid &&
            record.is_custom
        )

        const remark =
          remarks &&
          remarks.find(
            (item) =>
              item.user_id === get(currentUser, 'user.id') &&
              (item?.learning_outcome_channel_custom_id === record?.id ||
                item?.learning_outcome_id === record?.id)
          )

        return (
          <Remark
            record={record}
            specificCustom={specificCustom}
            specificOutcome={specificOutcome}
            handleKeyPress={handleKeyPress}
            setRemarks={setRemarks}
            remarks={remarks}
            remark={remark}
            index={index}
            placeholder={
              isUnicornOrBell
                ? 'Add Remarks (Type at least 3 characters)'
                : 'Add Remark'
            }
          />
        )
      },
    },
    {
      title: 'Grade',
      width: '5%',
      render: (record, i, index) => {
        const specificOutcome = learningOutcomeUser.find(
          (item) => item.learning_outcome_id === record.id && !record.is_custom
        )

        const specificCustom = listSetOutcomeCustom.find(
          (item) =>
            record.id === item.learning_outcome_channel_custom_id &&
            item.user_id === uid &&
            record.is_custom
        )
        let competent = null
        if (specificOutcome) {
          competent = get(specificOutcome, 'competent', null)
        } else if (specificCustom) {
          competent = get(specificCustom, 'competent', null)
        }

        return (
          <div className={`quiz__head__competent`}>
            <div
              className={`quiz__head__check ${
                competent === true ? 'quiz__head__check-active' : ''
              }`}
              onClick={() =>
                handleTick(record, specificOutcome, index, specificCustom)
              }
            >
              <img
                className="checked-grade"
                src={
                  competent === true || competent === null
                    ? images.header.check
                    : images.header.check_inactive
                }
                alt=""
              />
            </div>
            <div
              className={`quiz__head__check ${
                competent === false ? 'quiz__head__check__x-active' : ''
              }`}
              onClick={() =>
                handleUnCheckAnswer(
                  record,
                  specificOutcome,
                  index,
                  specificCustom
                )
              }
            >
              <img
                className="checked-grade"
                src={
                  competent === false || competent === null
                    ? images.admin.x
                    : images.admin.x_inactive
                }
                alt=""
              />
            </div>
          </div>
        )
      },
    },

    {
      title: 'Action',
      width: '5%',
      render: (record, i, index) => {
        return (
          <div className="action" onClick={() => handleRemove(record)}>
            <img src={images.admin.trash_icon} />
          </div>
        )
      },
    },
  ]

  const handleRemove = (record) => {
    dispatch(showDeleteLearningOutcome(record, selectedSchedule, id))
  }
  const handleKeyPress = (e, record, specificOutcome, specificCustom) => {
    const is_custom = get(record, 'is_custom', false)
    const remark = e.currentTarget.value
    if (e.key === 'Enter') {
      const data = {
        userId: uid,
        outcomeId: get(record, 'id', ''),
        channel_id: parseInt(id),
        remark,
      }
      if (!is_custom && specificOutcome) {
        dispatch(
          setCompetentLearningOutcome({
            ...data,
            competent: get(specificOutcome, 'competent'),
            id: get(specificOutcome, 'id', ''),
            type: get(specificOutcome, 'type', ''),
          })
        )
      } else if (!is_custom && !specificOutcome) {
        dispatch(setCompetentLearningOutcome(data))
      }

      if (is_custom && specificCustom) {
        dispatch(setListOutcomeCustom({ ...specificCustom, remark }))
      } else if (is_custom && !specificOutcome) {
        const params = {
          channel_id: parseInt(id),
          user_id: uid,
          remark,
          learning_outcome_channel_custom_id: record.id,
        }

        dispatch(setListOutcomeCustom(params))
      }
    }
  }

  const handleTick = (record, specificOutcome, index, specificCustom) => {
    const is_custom = get(record, 'is_custom', false)
    const remark =
      remarks &&
      remarks.find(
        (item) =>
          item.user_id === get(currentUser, 'user.id') &&
          item?.learning_outcome_channel_custom_id === record?.id
      )

    const data = {
      userId: uid,
      outcomeId: get(record, 'id', ''),
      competent: true,
      channel_id: parseInt(id),
      remark: get(remark, 'remark', ''),
    }

    if (remark && remark.remark && remark.remark?.length < 3) {
      return showError('Remark must be minimum 3 characters.', 2000)
    }

    // Old Outcome
    if (!is_custom && specificOutcome) {
      dispatch(
        setCompetentLearningOutcome({
          ...data,
          id: get(specificOutcome, 'id', ''),
          type: get(specificOutcome, 'type', ''),
        })
      )
    } else if (!is_custom && !specificOutcome) {
      dispatch(setCompetentLearningOutcome(data))
    }

    // New Outcome
    if (is_custom && specificCustom) {
      dispatch(
        setListOutcomeCustom({
          ...specificCustom,
          competent: true,
          remark: remark?.remark || '',
        })
      )
    } else if (is_custom && !specificCustom) {
      const params = {
        channel_id: parseInt(id),
        user_id: uid,
        competent: true,
        learning_outcome_channel_custom_id: record.id,
        remark: remark?.remark || '',
      }

      dispatch(setListOutcomeCustom(params))
    }
  }

  const handleUnCheckAnswer = (
    record,
    specificOutcome,
    index,
    specificCustom
  ) => {
    const is_custom = get(record, 'is_custom', false)
    const remark =
      remarks &&
      remarks.find(
        (item) =>
          item.user_id === get(currentUser, 'user.id') &&
          item?.learning_outcome_channel_custom_id === record?.id
      )
    const data = {
      userId: uid,
      outcomeId: get(record, 'id', ''),
      competent: false,
      channel_id: parseInt(id),
      remark: get(remark, 'remark', ''),
    }

    if (remark && remark?.remark && remark?.remark?.length < 3) {
      return showError('Remark must be minimum 3 characters.', 2000)
    }

    if (!is_custom && specificOutcome) {
      dispatch(
        setCompetentLearningOutcome({
          ...data,
          id: get(specificOutcome, 'id', ''),
          type: get(specificOutcome, 'type', ''),
        })
      )
    } else if (!is_custom && !specificOutcome) {
      dispatch(setCompetentLearningOutcome(data))
    }

    if (is_custom && specificCustom) {
      dispatch(
        setListOutcomeCustom({
          ...specificCustom,
          competent: false,
          remark: remark?.remark || '',
        })
      )
    } else if (is_custom && !specificCustom) {
      const params = {
        channel_id: parseInt(id),
        user_id: uid,
        competent: false,
        learning_outcome_channel_custom_id: record.id,
        remark: remark?.remark || '',
      }

      dispatch(setListOutcomeCustom(params))
    }
  }

  return (
    <Table
      columns={columnsTable}
      dataSource={
        isCustom
          ? [...learningOutcome, ...listCustomOutcomeUser]
          : learningOutcome
      }
      rowKey={(record) => (record.is_custom ? record.id + 'custom' : record.id)}
      onRow={(record) => ({
        onClick: () => setSelectedRow(record),
      })}
      pagination={false}
      className="outcome-table"
    />
  )
}

export default TableLearningOutCome
