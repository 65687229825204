import React from 'react'
import HsSteps from '../HsSteps'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from '../../../Home/selectors'
import { DOMAIN } from '../../../../routes'
import { get } from 'lodash'

const CheckoutProgressBar = ({
  complete,
  failed,
  indexActive,
  firstMediaDomain,
  cyberQuoteDomain,
  isUnicornDomain,
  requiredSchedule,
  isSingaporeSkillsAcademies,
}) => {
  const domain = useSelector(makeSelectDomain())

  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  console.log('wfaDomain', wfaDomain)

  const renderSteps = () => {
    if (isSingaporeSkillsAcademies || wfaDomain) {
      return [
        {
          title: 'LOGIN OR REGISTER',
        },
        {
          title: 'Complete',
        },
      ]
    }
    if (isUnicornDomain) {
      return [
        {
          title: 'LOGIN',
        },
        {
          title: 'Confirm',
        },
        { title: 'Profile' },
        {
          title: 'Billing',
        },
        {
          title: complete ? 'Success' : 'Complete',
        },
      ]
    }
    return [
      {
        title: 'LOGIN OR REGISTER',
      },
      {
        title: 'Confirm',
      },
      { title: 'Profile' },
      {
        title: 'Billing',
      },
      {
        title: complete ? 'Success' : 'Complete',
      },
    ]
  }
  let stepConfig = renderSteps()

  if (!requiredSchedule) {
    const index = stepConfig.findIndex((step) => step.title === 'Confirm')
    if (index !== -1) stepConfig.splice(index, 1)
  }

  return (
    <HsSteps
      indexActive={indexActive}
      steps={stepConfig}
      complete={complete}
      failed={failed}
    />
  )
}

export default CheckoutProgressBar
