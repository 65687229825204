import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { get, uniqueId } from 'lodash'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'

import images from 'Themes/Images'
import EditSchedule from './components/EditSchedule'
import {
  showDetailsRegistration,
  showDeleteEnrollmentModal,
} from 'container/Modal/actions'
import { updateEnroll } from 'container/Home/actions'
import { isScheduleAlwaysAvailable } from 'utils/helper'
import './styles.scss'
import { makeSelectUserPaymentMethod } from '../../../Home/selectors'
import { getAccessToken } from 'utils/request'
import config from 'src/global-config'
import PaymentMethod from './components/PaymentMethod'

const TableEnrollment = ({
  listCourseEnrollment,
  userData,
  userProfile,
  domain,
  setIsUpdateAllInvoices,
  setCourseAllInvoices,
}) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [courseSchedule, setCourseSchedule] = useState('')
  const [listSales, setListSales] = useState(null)
  const [listEnrollment, setListEnrollment] = useState(listCourseEnrollment)
  const userPaymentMethod = useSelector(makeSelectUserPaymentMethod())
  const [visibleModal, setVisibleModal] = useState(false)
  const [selectedCourse, setSelectCourse] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    const getSalesUsers = async () => {
      try {
        const requestUrl = `${config.baseUrl}/api/users?per_page=1000&ignore_company=true&group_ids=67`
        const res = await fetch(requestUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        if (Array.isArray(data.data) && data.data.length !== 0) {
          setListSales(data.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
    getSalesUsers()
  }, [])

  useEffect(() => {
    if (listCourseEnrollment) {
      const nextListEnrolment = listCourseEnrollment?.reduce((prev, curr) => {
        if (curr?.partners?.length > 1) {
          const temp = curr?.partners?.map((i) => {
            return { ...curr, partners: [i] }
          })
          return [...prev, ...temp]
        }
        return [...prev, curr]
      }, [])

      setListEnrollment(nextListEnrolment)
    }
  }, [listCourseEnrollment])

  const columns = [
    {
      title: 'Course',
      width: 250,
      render: (record) => <span>{record.name}</span>,
    },
    {
      title: 'Class code',
      width: 140,
      render: (record) => {
        const requireSchedule = get(record, 'is_schedule')
        if (!requireSchedule) {
          return ''
        }

        const schedule = get(record, 'partners[0].schedule')
        return <span>{get(schedule, 'class_code', '')}</span>
      },
    },
    {
      title: 'Schedule',
      width: 210,
      render: (record) => {
        const requireSchedule = get(record, 'is_schedule')
        const startDate = get(record, 'partners[0].schedule.start_date', '')
        const endDate = get(record, 'partners[0].schedule.end_date', '')
        const scheduleId = get(record, 'partners[0].schedule_id', '')
        if (!requireSchedule) {
          return ''
        }
        const isAlwayAvailable = isScheduleAlwaysAvailable(startDate, endDate)
        const label = `${
          isAlwayAvailable
            ? 'Always Available'
            : `${dayjs(startDate).format('DD MMM')} - ${dayjs(endDate).format(
                'DD MMM YYYY'
              )}`
        }`
        const obj = { label, value: scheduleId }
        return !record.edit ? (
          <span>{label}</span>
        ) : (
          <EditSchedule
            course={record}
            schedule={obj}
            courseSchedule={courseSchedule}
            setCourseSchedule={setCourseSchedule}
          />
        )
      },
    },
    {
      title: 'Date enrol',
      width: 190,
      render: (record) => {
        return (
          <span>
            {dayjs(get(record, 'partners[0].create_date', '')).format('LLL')}
          </span>
        )
      },
    },
    // {
    //   title: 'Sales agent',
    //   width: 160,
    //   ellipsis: true,
    //   render: (record) => {
    //     const refId = get(record, 'partners[0].ref_id', 0)
    //     const user =
    //       Array.isArray(listSales) &&
    //       listSales.find((item) => item.id === refId)

    //     return <span>{get(user, 'partner.name', '')}</span>
    //   },
    // },
    {
      title: 'Payment method',
      width: 170,
      render: (record) => {
        const paymentMethod = userPaymentMethod?.find(
          (item) => item?.channel_id === record?.id
        )
        const isDeleted = record?.partners?.[0]?.deleted_at

        return (
          !isDeleted && (
            <>
              {paymentMethod && (
                <div
                  className="view-details"
                  onClick={() => {
                    setVisibleModal(true)
                    setSelectCourse(record)
                    setIsEdit(true)
                  }}
                >
                  Edit Payment
                </div>
              )}
              <div
                className="view-details"
                onClick={() => {
                  setVisibleModal(true)
                  setSelectCourse(record)
                  setIsEdit(false)
                }}
              >
                Add Payment
              </div>
            </>
          )
        )
      },
    },
    {
      title: 'Registration details',
      width: 200,
      render: (record) => {
        return (
          <div
            className="view-details"
            onClick={() => handleViewDetails(record)}
          >
            View Details
          </div>
        )
      },
    },
    {
      title: 'Removed Status',
      width: 180,
      render: (record) => {
        const isDeleted = record?.partners?.[0]?.deleted_at
        return isDeleted ? 'Removed' : ''
      },
    },
    {
      title: 'Date Removed',
      width: 180,
      render: (record) => {
        const isDeleted = record?.partners?.[0]?.deleted_at
        return isDeleted ? dayjs(isDeleted).format('LLL') : ''
      },
    },
    {
      title: 'Removed By',
      width: 180,
      render: (record) => {
        const isDeleted = record?.partners?.[0]?.deleted_at
        const removedBy = record?.partners?.[0]?.created_by_user
        return removedBy && isDeleted ? removedBy?.login : ''
      },
    },
    {
      title: 'IP',
      width: 140,
      render: (record) => {
        const isDeleted = record?.partners?.[0]?.deleted_at
        const data = record?.partners?.[0]
        return data && isDeleted ? data?.client_ip : ''
      },
    },
    {
      title: '',
      with: 100,
      render: (text, record, index) => {
        const isDeleted = record?.partners?.[0]?.deleted_at
        return isDeleted ? null : !record.edit ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                marginRight: 5,
              }}
              className="view-details"
              onClick={() => handleEdit(index)}
            >
              Edit
            </span>
            {/* <span style={{ marginLeft: 5, marginRight: 5 }}>
              <RegistrationRecord
                domain={domain}
                record={record}
                userProfile={userProfile}
                userData={userData}
              />
            </span> */}
            <span
              style={{
                backgroundColor: '#e7e7eb',
                padding: 2,
                borderRadius: 2,
              }}
            >
              {' '}
              <img
                src={images.admin.trash_icon}
                alt=""
                onClick={() => handleDelete(record)}
              />
            </span>
          </div>
        ) : (
          <>
            <span>
              <img src={images.admin.x} alt="" onClick={() => handleEdit(-1)} />
            </span>
            <span style={{ marginLeft: 10 }}>
              <img
                src={images.admin.save_icon}
                alt=""
                onClick={() => handleSave(record)}
              />
            </span>
          </>
        )
      },
    },
  ]

  const handleSave = (record) => {
    if (typeof courseSchedule === 'string') {
      handleEdit(-1)
    }

    if (typeof courseSchedule === 'object') {
      dispatch(
        updateEnroll({
          currentUser: userData,
          schedule: get(courseSchedule, 'value'),
          course: record,
        })
      )
    }
  }

  const handleDelete = (record) => {
    dispatch(showDeleteEnrollmentModal(record))
  }

  const handleEdit = (index) => {
    const newList = [...listEnrollment]
    for (let i = 0; i < listEnrollment.length; i++) {
      if (i === index) {
        newList[i].edit = true
      } else {
        newList[i].edit = false
      }
    }
    setListEnrollment(newList)
  }

  const handleViewDetails = (record) => {
    const partner = get(record, 'partners[0]', null)
    if (partner) {
      dispatch(
        showDetailsRegistration({ ...partner, ...userData, course: record })
      )
    }
  }

  return (
    <div className="table-schedule__wrap">
      <Table
        columns={columns}
        dataSource={listEnrollment}
        rowClassName="table-row"
        pagination={{ defaultPageSize: 8 }}
        rowKey={(record) => uniqueId(record.id)}
        scroll={isMobile ? { x: 1000, y: 400 } : { x: 1280 }}
      />

      <PaymentMethod
        visible={visibleModal}
        course={selectedCourse}
        userProfile={userProfile}
        onRefresh={() => setVisibleModal(false)}
        userPaymentMethod={userPaymentMethod}
        isEdit={isEdit}
        setIsUpdateAllInvoices={setIsUpdateAllInvoices}
        setCourseAllInvoices={setCourseAllInvoices}
      />
    </div>
  )
}

export default TableEnrollment
