import React, { useEffect, useState, useMemo } from 'react'
import { Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import BtnPrimary from 'component/BtnPrimary'
import {
  hideExportInvoices,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { loadListCourse } from 'container/Home/actions'
import { useSelector } from 'react-redux'
import { makeSelectListCourse } from 'container/Home/selectors'
import {
  mapListCourseToDropDown,
  mapScheduleToDropDown,
  parseSafe,
} from 'utils/helper'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { getProfile } from 'utils/profileUtils'
const ExportInvoicesModal = ({
  dispatch,
  courseSelected,
  showExportInvoices,
}) => {
  const courses = useSelector(makeSelectListCourse())
  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)

  const course = useMemo(() => {
    if (selectedCourse && courses) {
      return courses.find((course) => course.id === selectedCourse)
    }
  }, [selectedCourse, courses])

  const requireSchedule = get(course, 'is_schedule', false)
  const channelID = get(selectedCourse, 'value', '')

  useEffect(() => {
    if (courseSelected) {
      setSelectedCourse({
        value: courseSelected.id,
        label: courseSelected.name,
      })
    }
  }, [courseSelected])

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getSchedules(selectedCourse, true, null, true, true))
    }
  }, [selectedCourse])

  useEffect(() => {
    dispatch(
      loadListCourse({
        admin: true,
        filter: 'sequence',
        newFilter: 'per_page=1000&ignore_per_page=true',
      })
    )
  }, [])

  const handleCancel = () => {
    dispatch(hideExportInvoices())
  }

  const handleExport = async (isExportAll = false) => {
    const zip = new JSZip()
    const folderZip = zip.folder('')
    if (selectedCourse) {
      let requestURL = `${
        config.baseUrl
      }/api/payments?include_delete=true&channel_id=${selectedCourse}${
        !isExportAll ? `&schedule_id=${get(selectedSchedule, 'value', 0)}` : ''
      }&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        const uniqueDataArray = !isExportAll
          ? Array.from(
              data?.data
                ?.reduce((map, item) => {
                  const listMultiplePayment = data?.data?.filter(
                    (payment) => payment?.user_id === item?.user_id
                  )

                  if (
                    !map.has(item.user_id) &&
                    listMultiplePayment?.length > 0
                  ) {
                    const attachmentItem = listMultiplePayment.find(
                      (payment) => payment?.attachment_data
                    )

                    if (attachmentItem) {
                      map.set(item.user_id, attachmentItem)
                    } else {
                      const invoiceItem = listMultiplePayment.find(
                        (payment) => payment?.invoice_url
                      )
                      if (invoiceItem) {
                        map.set(item.user_id, invoiceItem)
                      }
                    }
                  }
                  return map
                }, new Map())
                .values()
            )
          : data?.data

        if (Array.isArray(uniqueDataArray) && uniqueDataArray?.length > 0) {
          const requestsUsersInfo = []
          for (let i = 0; i < uniqueDataArray?.length; i++) {
            const userId = get(uniqueDataArray, `[${i}].user_id`)
            requestsUsersInfo.push(getUserInfo(userId))
          }

          const usersInfo = await Promise.all(requestsUsersInfo)

          for (let i = 0; i < uniqueDataArray?.length; i++) {
            try {
              const userPayment = get(uniqueDataArray, `[${i}]`, null)
              if (userPayment) {
                const additionalData = parseSafe(userPayment?.additional_info)
                const invoiceNo = get(additionalData, 'invoice_no', '')
                const dateEnrol = dayjs(
                  get(userPayment, 'write_date', '')
                ).format('YYMMDD')

                const fileName =
                  selectedCourse === 531
                    ? `WDS_INV_${
                        !userPayment.deleted_at ? '' : 'cancelled_'
                      }${dateEnrol}_${get(usersInfo, `[${i}].name`, '').replace(
                        /\//g,
                        ''
                      )}.pdf`
                    : `${
                        !userPayment.deleted_at ? '' : 'cancelled_'
                      }${dateEnrol}_${get(usersInfo, `[${i}].name`, '').replace(
                        /\//g,
                        ''
                      )}${invoiceNo ? `_${invoiceNo}` : ''}.pdf`
                const paymentMethod = get(userPayment, 'payment_method', '')

                if (
                  paymentMethod === 'credit' &&
                  get(userPayment, 'attachment_data', '')
                ) {
                  folderZip.file(
                    fileName,
                    get(userPayment, 'attachment_data', ''),
                    { base64: true }
                  )
                }

                if (
                  (paymentMethod === 'paynow' ||
                    paymentMethod === 'company_sponsored') &&
                  get(userPayment, 'invoice_url', '')
                ) {
                  folderZip.file(
                    fileName,
                    get(userPayment, 'invoice_url', ''),
                    { base64: true }
                  )
                }
              }
            } catch (err) {
              dispatch(hideGlobalLoading())
              console.error('Something went wrong!', err)
            }
          }
        }

        folderZip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, 'invoices.zip')
        })

        handleCancel()
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const getUserPayment = async (userId) => {
    try {
      const request = `${config.baseUrl}/api/users/${userId}/payment-method?channel_id=${channelID}&include_delete=true&order_by=id%20desc`
      const res = await fetch(request, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      const data = await res.json()

      return get(data, 'data', [])
    } catch (error) {
      console.log('error', error)
    }
  }

  const getUserInfo = async (userId) => {
    try {
      const request = `${config.baseUrl}/api/users/${userId}/profiles`
      const res = await fetch(request, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      const data = await res.json()
      if (Array.isArray(data.data)) {
        const profileDomainID = courseSelected?.website_id
        return getProfile(profileDomainID, data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Modal visible={showExportInvoices} footer={null} onCancel={handleCancel}>
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Export Invoices
          <BtnPrimary
            name="Export All"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginLeft: 20,
            }}
            handleClick={() => handleExport(true)}
          />
        </div>
        <div className="sc-modal__label">Courses</div>

        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>

            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}

        {(selectedCourse && selectedSchedule) || !requireSchedule ? (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={() => handleExport(false)}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export default ExportInvoicesModal
