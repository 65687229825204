import { get } from 'lodash'
import dayjs from 'dayjs'
import square from './square.png'
import square_ticked from './square_ticked.png'

import { assessmentItemTemplateBells } from '../AssessmentRecord/Bells'
import { assessmentItemTemplateUnicorn } from './Unicorn'

export const assessmentItem = ({
  doc,
  data,
  listUserProfile,
  listUserStatus,
  domain,
  allTypes,
  proficieny_name,
  proficieny_id,
  listOutcome,
  listSetOutcomeCustom,
  mergeLearningOutcome,
  listSetOutcomeStudents,
}) => {
  let countC = 0
  const user = get(data, 'user', '')
  const userId = get(user, 'id', '')
  const passport = get(listUserProfile, `[${userId}].passport`, '')
  const userStatus = listUserStatus.find((item) => item.user_id === userId)
  const submitMarkDate = get(userStatus, 'write_date', '')

  doc.setFontSize(24)
  doc.text(get(domain, 'name', ''), 20, 60)

  doc.setFontSize(16)
  doc.text('ASSESSMENT RECORDS', 20, 150)
  let i = 0
  for (let key in allTypes) {
    if (Array.isArray(allTypes[key]) && allTypes[key].length > 0) {
      if (i !== 0) doc.addPage()
      const firstRender = i === 0
      doc.setFontSize(14)
      doc.text(key === 'null' ? '' : key, 20, firstRender ? 180 : 80)

      doc.setFontSize(11)
      doc.text(
        'This document is to record the evidence gathered for the competency assessment of the following',
        20,
        firstRender ? 220 : 140
      )

      doc.setFontSize(11)
      doc.text(
        `candidate for the skill standard, ${proficieny_name} (${proficieny_id}).`,
        20,
        firstRender ? 240 : 160
      )
      // table

      doc.autoTable({
        margin: { left: 20 },
        startY: firstRender ? 280 : 180,
        startX: 20,
        theme: 'plain',
        styles: {},
        bodyStyles: {
          lineWidth: 0.6,
          lineColor: 12,
          minCellHeight: 40,
        },
        body: [
          [
            `Candidate's Name (as in NRIC): ${get(user, 'partner.name', '')}`,
            `NRIC No: ${passport}`,
          ],
          [
            `Assessor's Name: ${get(
              userStatus,
              'create_user.partner.name',
              ''
            )}`,
            'Assessment Venue: Online Class',
          ],
          [
            `Date of Assessment: ${
              submitMarkDate ? dayjs(submitMarkDate).format('DD/MM/YYYY') : ''
            }`,
            'Duration of Assessment: 60min',
          ],
        ],
      })

      // table 1

      doc.autoTable({
        margin: { left: 20 },
        startY: firstRender ? 450 : 350,
        showHead: 'firstPage',
        theme: 'grid',
        rowPageBreak: 'avoid',
        columnStyles: {
          0: { cellWidth: 170 },
          1: { cellWidth: 170 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 115 },
        },
        bodyStyles: {
          lineWidth: 0.6,
          lineColor: 12,
        },
        headStyles: {
          lineWidth: 0.6,
          lineColor: 12,
          valign: 'middle',
          halign: 'center',
          fillColor: [217, 217, 217],
          textColor: [32, 32, 32],
        },
        head: [
          [
            {
              content: 'Learning Outcome',
              rowSpan: 2,
            },
            { content: 'Assessment Criteria', rowSpan: 2 },
            { content: 'Please tick', colSpan: 2 },
            { content: 'Remarks', rowSpan: 2 },
          ],
        ],
        didDrawCell: function (draw) {
          if (draw.cell.raw.key === 'mark' && draw.cell.raw.content === '  ') {
            var cell = draw.cell
            doc.addImage(
              'https://lh3.googleusercontent.com/QfgjAd7jfGbt5KwEsZ8WLmzVEq0Npb3--LgMMPLz7PZuSptDgcNYZxTSk2CN3_Fss5yZdGcfkbAJReT_ek8k8aB5V3M4kOwqFBhNlplClR34PFjt=s0',
              cell.x + cell.width / 2 - 10,
              cell.y + cell.height / 2 - 10,
              20,
              20
            )
          }

          if (draw.cell.raw.key === 'mark' && draw.cell.raw.content === ' ') {
            var cell = draw.cell
            doc.addImage(
              'https://lh3.googleusercontent.com/_kwqqknsSWAavjQ606XM5csbbP00AAB-NPoPgEqpKSxlWFjMCSNB20V0Y25zw0-ZLUP6ATyFPkjf0GDVTogWpg865seMWbAN2Y_yYnyNtkOC1jqt=s0',
              cell.x + cell.width / 2 - 10,
              cell.y + cell.height / 2 - 10,
              20,
              20
            )
          }
        },
        body: [
          [
            '',
            '',
            {
              content: 'C',
              styles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [217, 217, 217],
                textColor: [32, 32, 32],
                fontStyle: 'bold',
              },
            },
            {
              content: 'NYC',
              styles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [217, 217, 217],
                textColor: [32, 32, 32],
                fontStyle: 'bold',
              },
            },
            '',
          ],
          ...allTypes[key].map((outcome) => {
            const isCustom = outcome.is_custom
            const assessment_criteria = get(outcome, 'assessment_criteria', {})
            const learningOutcome = listOutcome.find(
              (item) => item.id === assessment_criteria.learning_outcome_id
            )
            const customAnswer = listSetOutcomeCustom.find(
              (item) =>
                item.user_id === userId &&
                item.learning_outcome_channel_custom_id === outcome.id
            )

            const answer = listSetOutcomeStudents.find(
              (item) =>
                item.learning_outcome_id === outcome.id &&
                item.user_id === userId
            )

            countC += isCustom
              ? get(customAnswer, 'competent')
                ? 1
                : 0
              : get(answer, 'competent')
              ? 1
              : 0

            let nullAnswer = isCustom
              ? get(customAnswer, 'competent') === null
              : get(answer, 'competent') === null

            if (isCustom && !customAnswer) {
              nullAnswer = true
            }

            if (!isCustom && !answer) {
              nullAnswer = true
            }
            return [
              {
                content: `${
                  isCustom
                    ? get(outcome, 'learning_outcome')
                    : get(learningOutcome, 'outcome', '')
                } `,
              },
              {
                content: `${
                  isCustom
                    ? get(outcome, 'assessment_criteria')
                    : get(assessment_criteria, 'criteria', '')
                } `,
              },
              {
                content: nullAnswer
                  ? ''
                  : isCustom
                  ? get(customAnswer, 'competent')
                    ? ' '
                    : ''
                  : get(answer, 'competent')
                  ? ' '
                  : '',
                key: 'mark',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                },
              },
              {
                content: nullAnswer
                  ? ''
                  : isCustom
                  ? !get(customAnswer, 'competent')
                    ? '  '
                    : ''
                  : !get(answer, 'competent')
                  ? '  '
                  : '',
                key: 'mark',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                },
              },
              {
                content: `${
                  isCustom
                    ? get(customAnswer, 'remark', '') || 'N/A'
                    : get(answer, 'remark', '') || 'N/A'
                } `,
              },
            ]
          }),
        ],
      })
      i++
    }
  }

  // table 2

  const isPassAssessment =
    countC === mergeLearningOutcome.length && mergeLearningOutcome.length !== 0

  doc.autoTable({
    margin: { left: 20 },
    startY: doc.autoTable.previous.finalY || 0 + 50,
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 250 },
      1: { cellWidth: 150 },
      2: { cellWidth: 40 },
    },
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
    },
    headStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      valign: 'middle',
      halign: 'center',
      fillColor: [217, 217, 217],
      textColor: [32, 32, 32],
    },
    head: [
      [
        {
          content: 'Assessment Outcome (CS)',
          rowSpan: 3,
        },
        { content: 'Please Tick', colSpan: 2 },
        { content: 'Remarks', rowSpan: 2 },
      ],
    ],
    body: [
      [
        '',
        {
          content: 'C',
          styles: {
            valign: 'middle',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [32, 32, 32],
            fontStyle: 'bold',
          },
        },
        {
          content: 'NYC',
          styles: {
            valign: 'middle',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [32, 32, 32],
            fontStyle: 'bold',
          },
        },
        { content: '' },
      ],
      [
        '',
        {
          content: `${isPassAssessment ? 'C' : ''}`,
          styles: {
            valign: 'middle',
            halign: 'center',
          },
        },
        {
          content: `${!isPassAssessment ? 'NYC' : ''}`,
          styles: {
            valign: 'middle',
            halign: 'center',
          },
        },
        { content: '' },
      ],
    ],
  })

  // table 3

  doc.autoTable({
    margin: { left: 20 },
    startY: doc.autoTable.previous.finalY,
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 250 },
      1: { cellWidth: 150 },
      2: { cellWidth: 40 },
    },
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
    },
    headStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      valign: 'middle',
      halign: 'center',
      fillColor: [217, 217, 217],
      textColor: [32, 32, 32],
    },
    head: [
      [
        {
          content: 'Candidate has acknowledged the outcome',
          rowSpan: 3,
        },
        { content: 'Please Tick', colSpan: 2 },
        { content: 'Remarks', rowSpan: 2 },
      ],
    ],
    body: [
      [
        '',
        {
          content: 'C',
          styles: {
            valign: 'middle',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [32, 32, 32],
            fontStyle: 'bold',
          },
        },
        {
          content: 'NYC',
          styles: {
            valign: 'middle',
            halign: 'center',
            fillColor: [217, 217, 217],
            textColor: [32, 32, 32],
            fontStyle: 'bold',
          },
        },
        { content: '' },
      ],
      [
        '',
        {
          content: 'C',
          styles: {
            valign: 'middle',
            halign: 'center',
          },
        },
        {
          content: '',
          styles: {
            valign: 'middle',
            halign: 'center',
          },
        },
        { content: '' },
      ],
    ],
  })
}

export const assessmentItemBell = ({
  doc,
  data,
  listUserProfile,
  listUserStatus,
  domain,
  allTypes,
  proficieny_name,
  proficieny_id,
  listOutcome,
  listSetOutcomeCustom,
  mergeLearningOutcome,
  listSetOutcomeStudents,
  courseName,
  selectedSchedule,
  course,
  currentUser,
  userStatus,
}) => {
  const user = get(data, 'user', '')
  const userId = get(user, 'id', '')
  const name =
    get(listUserProfile, `[${userId}].name`, '') || user?.partner?.name || ''

  const trainerProfile = get(listUserProfile, `[${userStatus?.create_uid}]`, '')
  const trainerName =
    get(trainerProfile, `name`, '') ||
    userStatus?.create_user?.partner?.name ||
    ''
  const trainerPassport = get(trainerProfile, `passport`, '')

  assessmentItemTemplateBells({
    doc,
    data,
    listUserProfile,
    listUserStatus,
    domain,
    allTypes,
    proficieny_name,
    proficieny_id,
    listOutcome,
    listSetOutcomeCustom,
    mergeLearningOutcome,
    listSetOutcomeStudents,
    courseName,
    selectedSchedule,
    course,
    trainerName,
    trainerPassport,
  })

  let countC = 0

  for (let key in allTypes) {
    // eslint-disable-next-line no-loop-func
    allTypes[key].forEach((outcome) => {
      const isCustom = outcome.is_custom
      const customAnswer = listSetOutcomeCustom.find(
        (item) =>
          item.user_id === userId &&
          item.learning_outcome_channel_custom_id === outcome.id
      )
      const answer = listSetOutcomeStudents.find(
        (item) =>
          item.learning_outcome_id === outcome.id && item.user_id === userId
      )

      countC += isCustom
        ? get(customAnswer, 'competent')
          ? 1
          : 0
        : get(answer, 'competent')
        ? 1
        : 0
    })
  }
  const submitMarkDate = get(userStatus, 'write_date', '')
  const signatureStudentDate = get(userStatus, 'signature_student_date', '')
  const signatureTrainerDate = get(userStatus, 'signature_student_date', '')

  const isPassAssessment =
    countC === mergeLearningOutcome.length && mergeLearningOutcome.length !== 0

  doc.addPage()
  doc.setFontSize(10)
  doc.text(
    'The candidate has been briefed on the assessment appeal procedure',
    20,
    60
  )
  doc.addImage(square_ticked, 'PNG', 20, 80, 40, 40)
  doc.text('YES', 65, 105)

  doc.addImage(square, 'PNG', 100, 80, 40, 40)
  doc.text('NO', 145, 105)
  doc.text('The candidate has been assessed as:', 20, 155)
  doc.addImage(
    isPassAssessment ? square_ticked : square,
    'PNG',
    40,
    175,
    40,
    40
  )
  doc.text('COMPETENT in the Skill:', 85, 195)
  doc.text(courseName, 230, 195)

  doc.addImage(
    !isPassAssessment ? square_ticked : square,
    'PNG',
    40,
    230,
    40,
    40
  )
  doc.text(
    `NOT YET COMPETENT
in the Skill:`,
    85,
    250
  )
  doc.text(courseName, 230, 250)

  doc.text(
    'By signing, the candidate is agreeing to accept the assessment outcome.',
    20,
    320
  )

  doc.text(
    `Candidate’s Name
(As in NRIC)`,
    20,
    370
  )
  doc.text(`: ${name || ''}`, 140, 370)
  doc.text(`: ____________________________________________________`, 140, 370)

  doc.text(`Candidate’s Signature:`, 20, 410)
  userStatus?.signature_student_name &&
    doc.addImage(userStatus?.signature_student_name, 'PNG', 160, 380, 37.5, 30)
  doc.text(` _________________________`, 140, 410)

  doc.text(`Date`, 320, 410)
  signatureStudentDate
    ? doc.text(`${dayjs(signatureStudentDate).format('DD/MM/YYYY')}`, 378, 408)
    : doc.text(
        `${submitMarkDate ? dayjs(submitMarkDate).format('DD/MM/YYYY') : ''}`,
        378,
        408
      )
  doc.text(`: __________________`, 348, 410)

  doc.text(`Assessor’s Name`, 20, 450)
  doc.text(`: ${trainerName}`, 140, 450)
  doc.text(`: ____________________________________________________`, 140, 450)

  doc.text(`Assessor’s Signature`, 20, 490)
  userStatus?.signature_trainer_name &&
    doc.addImage(userStatus?.signature_trainer_name, 'PNG', 160, 460, 37.5, 30)
  doc.text(`: _________________________`, 140, 490)

  doc.text(`Date`, 320, 490)
  signatureTrainerDate
    ? doc.text(`${dayjs(signatureTrainerDate).format('DD/MM/YYYY')}`, 378, 488)
    : doc.text(
        `${submitMarkDate ? dayjs(submitMarkDate).format('DD/MM/YYYY') : ''}`,
        378,
        488
      )
  doc.text(`: __________________`, 348, 490)

  doc.setFontSize(10)
  doc.text(`Copyright 2022 © BELLS Academic Group Pte Ltd`, 20, 800)
  doc.text(`All Right Reserved `, 20, 820)
}

export const assessmentItemUnicorn = ({
  doc,
  data,
  listUserProfile,
  listUserStatus,
  domain,
  allTypes,
  proficieny_name,
  proficieny_id,
  listOutcome,
  listSetOutcomeCustom,
  mergeLearningOutcome,
  listSetOutcomeStudents,
  courseName,
  selectedSchedule,
  course,
  currentUser,
}) => {
  const user = get(data, 'user', '')
  const userId = get(user, 'id', '')
  const name =
    get(listUserProfile, `[${userId}].name`, '') || user?.partner?.name || ''

  const trainerId = listSetOutcomeCustom?.find(
    (item) => item.user_id === userId
  )?.create_uid
  const trainerName = get(listUserProfile, `[${trainerId}].name`, '')

  const body = {
    doc,
    data,
    listUserProfile,
    listUserStatus,
    domain,
    allTypes,
    proficieny_name,
    proficieny_id,
    listOutcome,
    listSetOutcomeCustom,
    mergeLearningOutcome,
    listSetOutcomeStudents,
    courseName,
    selectedSchedule,
    course,
  }

  assessmentItemTemplateUnicorn(body)

  let countC = 0

  for (let key in allTypes) {
    // eslint-disable-next-line no-loop-func
    allTypes[key].forEach((outcome) => {
      const isCustom = outcome.is_custom
      const customAnswer = listSetOutcomeCustom.find(
        (item) =>
          item.user_id === userId &&
          item.learning_outcome_channel_custom_id === outcome.id
      )
      const answer = listSetOutcomeStudents.find(
        (item) =>
          item.learning_outcome_id === outcome.id && item.user_id === userId
      )

      countC += isCustom
        ? get(customAnswer, 'competent')
          ? 1
          : 0
        : get(answer, 'competent')
        ? 1
        : 0
    })
  }

  const isPassAssessment =
    countC === mergeLearningOutcome.length && mergeLearningOutcome.length !== 0

  doc.addPage()
  doc.setFontSize(10)
  doc.text(
    'The candidate has been briefed on the assessment appeal procedure',
    20,
    60
  )
  doc.addImage(square_ticked, 'PNG', 20, 80, 40, 40)
  doc.text('YES', 65, 105)

  doc.addImage(square, 'PNG', 100, 80, 40, 40)
  doc.text('NO', 145, 105)
  doc.text('The candidate has been assessed as:', 20, 155)
  doc.addImage(
    isPassAssessment ? square_ticked : square,
    'PNG',
    40,
    175,
    40,
    40
  )
  doc.text('COMPETENT in the Skill:', 85, 195)
  doc.text(courseName, 230, 195)

  doc.addImage(
    !isPassAssessment ? square_ticked : square,
    'PNG',
    40,
    230,
    40,
    40
  )
  doc.text(
    `NOT YET COMPETENT
in the Skill:`,
    85,
    250
  )
  doc.text(courseName, 230, 250)

  doc.addImage(square_ticked, 'PNG', 40, 330, 40, 40)
  doc.text(`I hereby acknowledge and I accept the overall results.`, 85, 350)
}
