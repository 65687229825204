import { Modal } from 'antd'
import React, { useMemo } from 'react'
import PaymentMethodForm from './PaymentMethodForm'

const PaymentMethod = ({
  isEdit,
  course,
  visible,
  onRefresh,
  userProfile,
  userPaymentMethod,
  setIsUpdateAllInvoices,
  setCourseAllInvoices,
}) => {
  const paymentMethod = useMemo(
    () => userPaymentMethod?.filter((item) => item?.channel_id === course?.id),
    [userPaymentMethod, course]
  )

  return (
    <Modal
      title={isEdit ? 'Edit Payment Method' : 'Add Payment Method'}
      visible={visible}
      footer={null}
      width="60vw"
      maskClosable={false}
      destroyOnClose
      onCancel={onRefresh}
    >
      <PaymentMethodForm
        course={course}
        onCancel={onRefresh}
        userProfile={userProfile}
        paymentMethod={isEdit ? paymentMethod : []}
        isEdit={isEdit}
        setIsUpdateAllInvoices={setIsUpdateAllInvoices}
        setCourseAllInvoices={setCourseAllInvoices}
      />
    </Modal>
  )
}

export default React.memo(PaymentMethod)
