import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { get, isEmpty, sortBy } from 'lodash'
import { Row, Col, Checkbox, Space, message } from 'antd'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import axiosInstance from 'utils/axiosInstance'
import * as navigation from 'utils/navigation'

import images from 'Themes/Images'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'
import UserProfile from '../../components/UserProfile'
import Enrollment from '../../components/Enrollment'
import CreateEnrollment from '../../components/CreateEnrollment'
import AssignClient from '../../components/AssignClient'
import { getUserData, getUserProfile } from '../../actions'
import { isAdminRole, isTrainerRole } from 'utils/userUtils'
import { ERROR_MESSAGE } from 'utils/constants'
import { showSuccess } from 'utils/notification'
import { LoginFailed, LoginSuccess } from 'container/Auth/actions'
import { makeSelectUserData, makeSelectUserProfile } from '../../selectors'
import { makeSelectError } from 'container/Auth/selectos'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { createOrUpdateUser } from 'src/customs/SuperAdmin/container/AdminActor/actions'
import { makeSelectListCourse } from 'container/Home/selectors'
import { makeSelectDomain } from 'container/Home/selectors'
import './styles.scss'
import { getAccessToken } from 'utils/request'
import SFCClaims from '../../components/SFCClaims/index'
import UserForm from './UserForm'
import { getUserRoles } from 'utils/userUtils'
import { getClients } from 'utils/request'
import useFirstRender from 'src/hooks/useFirstRender'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { DOMAIN } from '../../../../routes'
import { isMainAdmin, isLoginAsRoleAdmin } from 'utils/helper'
import GroupSelect from '../ClassManagement/GroupSelect'
import useGetFolders from '../../../../hooks/useGetFolders'

const SignUpSchema = Yup.object().shape({
  create: Yup.string().required(ERROR_MESSAGE),
  create_name: Yup.string().required(ERROR_MESSAGE),
})

const defaultFilter = {
  ignore_per_page: true,
  per_page: 100,
  order_by: 'id desc',
}

const CreateUser = () => {
  const { search } = useLocation()
  const { user: userID } = parse(search)
  const dispatch = useDispatch()
  const history = useHistory()
  const error = useSelector(makeSelectError())
  const userData = useSelector(makeSelectUserData())
  const userFolder = get(userData, 'user_folders', [])
  const userClients = get(userData, 'user_clients', [])
  const userGroups = get(userData, 'user_groups', [])
  const isAdminUserData = isAdminRole(userGroups)
  const [isEditGroup, setIsEditGroup] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedRecordGroup, setSelectedRecordGroup] = useState(null)

  const userProfile = useSelector(makeSelectUserProfile())
  const listCourse = useSelector(makeSelectListCourse())
  const domain = useSelector(makeSelectDomain())
  const [edit, setEdit] = useState(null)
  const [clients, setClients] = useState(null)
  const isFirstRender = useFirstRender()
  const currentUser = useSelector(makeSelectCurrentUser())
  const currentUserClients = get(currentUser, 'user_clients', [])
  const isAdmin = isMainAdmin(currentUser)
  const isLoginAsAdmin = isLoginAsRoleAdmin(currentUser)
  const folders = useGetFolders()
  const [isUpdateAllInvoices, setIsUpdateAllInvoices] = useState(false)
  const [courseAllInvoices, setCourseAllInvoices] = useState()

  const userGroupFolders = useMemo(() => {
    if (!isEmpty(folders) && !isEmpty(userFolder)) {
      const arr = userFolder?.map((item) => {
        const folder = folders?.find((item2) => {
          return item2.id === item?.folder_id
        })

        if (folder) {
          if (folder.parent_id) {
            const previousFolder = folders?.find((item2) => {
              return item2.id === folder?.parent_id
            })
            if (previousFolder) {
              if (previousFolder?.parent_id) {
                const rootFolder = folders?.find((item2) => {
                  return item2.id === previousFolder?.parent_id
                })

                if (rootFolder) {
                  return {
                    ...item,
                    parentFolder: [rootFolder, previousFolder, folder],
                  }
                }
              }

              return {
                ...item,
                parentFolder: [previousFolder, folder],
              }
            }
          }
          return { ...item, parentFolder: [folder] }
        }
        return item
      })
      return arr
    } else {
      return userFolder
    }
  }, [userFolder, folders])

  const isAscendoDomain =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO
  const isUnicornDomain = domain?.domain === DOMAIN.UNICORN_DOMAINs
  const isBellDomain = domain && domain.domain === DOMAIN.AGB_EDUCATION
  const visibleLoginAsUser = useMemo(
    () =>
      userData &&
      !isAdminUserData &&
      isLoginAsAdmin &&
      ((isAscendoDomain && currentUser?.user_clients?.length === 0) ||
        isUnicornDomain ||
        isBellDomain),
    [
      isLoginAsAdmin,
      isAdminUserData,
      isAscendoDomain,
      userData,
      isUnicornDomain,
      isBellDomain,
      currentUser,
    ]
  )

  useEffect(() => {
    if (userID) {
      formik.resetForm()
      dispatch(getUserData(userID))
      dispatch(getUserProfile(userID))
    }
  }, [userID])

  useEffect(() => {
    const getClientsData = async () => {
      const data = await getClients(defaultFilter)
      setClients(data)
    }
    if (!isFirstRender) {
      getClientsData()
    }
  }, [isFirstRender])

  useEffect(() => {
    if (userData) {
      const userGroups = get(userData, 'user_groups', [])
      formik.setFieldValue('admin', !!isAdminRole(userGroups))
      formik.setFieldValue('trainer', !!isTrainerRole(userGroups))
      formik.setFieldValue('create', get(userData, 'login', ''))
      formik.setFieldValue('create_name', get(userData, 'partner.name', ''))
    }
  }, [userData])

  const formik = useFormik({
    initialValues: {
      create: '',
      create_password: '',
      create_name: '',
      admin: false,
      trainer: false,
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      if (userData) {
        dispatch(
          createOrUpdateUser({
            name: values.create_name,
            login: values.create,
            id: userData.id,
            password: values.create_password,
            role: getUserRoles({
              admin: values.admin,
              trainer: values.trainer,
            }),
          })
        )
        if (isAscendoDomain) {
          await assignClientToAdmin(userData?.id)
        }
        setEdit(false)
      } else {
        let body = {
          name: values.create_name,
          login: values.create,
          password: values.create_password,
        }

        const requestURL = `${config.baseUrl}/api/users/signup`
        dispatch(showGlobalLoading())
        try {
          const res = await fetch(requestURL, {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
            method: 'POST',
            body: JSON.stringify(body),
          })
          const data = await res.json()
          const error = get(data, 'errors', null)
          const result = get(data, 'data.id', null)

          if (error) {
            const errorText =
              'Oops! You can not have two users with the same email!'
            dispatch(hideGlobalLoading())
            return dispatch(LoginFailed(errorText))
          }

          await updateUserRole(result, values.admin, values.trainer)

          if (isAscendoDomain) {
            await assignClientToAdmin(result)
          }
          if (isAscendoDomain) {
            await handleUpdateGroup(null, result)
          }
          if (result) {
            showSuccess('Create User Success')
            history.push('/admin/user-management')
          }
          dispatch(hideGlobalLoading())
        } catch (err) {
          dispatch(hideGlobalLoading())
          console.log('err', err)
        }
      }
    },
  })

  const assignClientToAdmin = async (userID) => {
    if (formik.values?.client?.value) {
      try {
        await axiosInstance.put('/api/clients/users', {
          user_id: userID,
          client_id: formik.values?.client?.value,
        })
      } catch (error) {
        console.log('~ error', error)
      }
    }
  }

  const handleBackBtn = () => history.goBack()

  const handleCheckAdmin = async ({ target }) => {
    if (!!userData && !edit) {
      formik.setFieldValue('admin', target.checked)
      await updateUserRole(userID, target.checked, formik.values.trainer)
    } else {
      return formik.setFieldValue('admin', target.checked)
    }
  }

  const handleCheckTrainer = async ({ target }) => {
    if (!!userData && !edit) {
      formik.setFieldValue('trainer', target.checked)
      await updateUserRole(userID, formik.values.admin, target.checked)
    } else {
      return formik.setFieldValue('trainer', target.checked)
    }
  }

  const updateUserRole = async (userID, isAdmin, isTrainer) => {
    try {
      await axiosInstance.post(`${config.baseUrl}/api/users/${userID}/group`, {
        group_id: getUserRoles({ admin: isAdmin, trainer: isTrainer }),
      })
      dispatch(getUserData(userID))
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleUpdateGroup = async (item, ID) => {
    if (!isEmpty(selectedGroup)) {
      try {
        let payload = {
          folder_id: get(
            selectedGroup,
            `[${get(selectedGroup, 'length') - 1}]`
          ),
          user_id: ID ? ID : parseInt(userID),
        }
        if (item) {
          payload = { ...payload, id: item?.id }
        }

        await axiosInstance.put(`/api/users/${userID}/folders`, payload)

        if (item) {
          message.success('Update group successfully!')
        } else {
          message.success('Create group successfully!')
        }
        setIsAdd(false)
        setIsEditGroup(false)
        setSelectedGroup(null)
        setSelectedRecordGroup(null)
      } catch (error) {
        console.log('error', error)
      }
      dispatch(getUserData(userID))
    } else {
      setIsEditGroup(false)
      setIsAdd(false)
    }
  }

  const handleDeleteGroup = async () => {
    const existFolderGroup = get(userFolder, '[0]')
    let payload = {
      folder_id: existFolderGroup?.folder_id,
      user_id: parseInt(userID),
    }
    try {
      if (existFolderGroup) {
        await axiosInstance.delete(
          `/api/users/folders/${existFolderGroup?.id}`,
          payload
        )
        message.success('Delete group successfully!')
      }

      setIsEditGroup(false)
      setSelectedGroup(null)
    } catch (error) {
      console.log('error', error)
    }

    dispatch(getUserData(userID))
  }

  const handleLoginAsUser = () => {
    const {
      company_id,
      is_system,
      partner,
      partner_id,
      ref_id,
      id,
      user_clients,
      user_companies,
      user_groups,
      login,
    } = userData || {}
    const isAdmin = !!isAdminRole(user_groups)

    const isTrainer = !!isTrainerRole(user_groups)
    const resultUser = {
      company_id,
      is_admin: isAdmin,
      is_system,
      name: partner?.name,
      partner_display_name: partner?.name,
      partner_id,
      ref_id,
      uid: id,
      user_clients,
      user_companies,
      user_groups,
      username: login,
    }
    dispatch(LoginSuccess(resultUser))
    localStorage.setItem('loginAsUser', true)
    if (isAdmin || isTrainer) {
      navigation.navigate('/admin?page=1&perpage=15')
    } else {
      navigation.navigate('/?page=1&perpage=15')
    }
  }

  return (
    <div className="create-user__wrap">
      <div style={{ marginTop: 20 }}>
        <Space>
          <BtnPrimary
            name="Go Back"
            style={{
              fontWeight: 'bold',
              color: '#fff',
              marginBottom: 15,
            }}
            handleClick={handleBackBtn}
            iconLeft={images.course.arrow_left}
          />
          {visibleLoginAsUser && (
            <BtnPrimary
              name="Login As User"
              style={{
                fontWeight: 'bold',
                color: '#fff',
                marginBottom: 15,
              }}
              handleClick={handleLoginAsUser}
            />
          )}
        </Space>
      </div>
      <Row className="u-manage">
        {userData && !edit ? (
          <Col md={12} xs={24}>
            <div
              className="u-manage__head"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h1 className="u-manage__heading">User Information</h1>

              <div>
                <BtnPrimary
                  name="Edit User"
                  style={{
                    fontWeight: 'bold',
                    color: '#fff',
                    marginBottom: 15,
                    marginLeft: 30,
                  }}
                  handleClick={() => setEdit(true)}
                  iconLeft={images.admin.save_icon}
                />
              </div>
            </div>
            <div className="create-user">
              <div className="form__field">
                <label htmlFor="email" className="form__label">
                  NAME (ACCORDING TO ID/PASSPORT)
                </label>
                <div className="profile__text">
                  {get(userData, 'partner.name', '')}
                </div>
              </div>

              <div className="form__field">
                <label htmlFor="email" className="form__label">
                  EMAIL ADDRESS
                </label>
                <div className="profile__text">
                  {get(userData, 'login', '')}
                </div>
              </div>
              {isAscendoDomain ? (
                <div className="form__field">
                  <label htmlFor="email" className="form__label">
                    GROUP
                  </label>
                  {Array.isArray(userGroupFolders) &&
                    sortBy(userGroupFolders, ['id'])?.map((item, index) => {
                      return (
                        <div
                          className="profile__text"
                          style={{ marginBottom: 12 }}
                        >
                          <Space>
                            {isEditGroup &&
                            selectedRecordGroup?.id === item?.id ? (
                              <div style={{ width: '280px' }}>
                                <GroupSelect
                                  multiple={false}
                                  value={
                                    selectedGroup
                                      ? selectedGroup
                                      : item?.parentFolder?.map(
                                          (item2) => item2.id
                                        )
                                  }
                                  onChange={(values) => {
                                    setSelectedGroup(values)
                                  }}
                                  data={folders}
                                />
                              </div>
                            ) : (
                              <div>
                                <span className="bold">{index + 1}.</span>
                                {'  '}
                                {item?.parentFolder
                                  ?.map((item2) => item2.name)
                                  ?.join(' > ')}
                              </div>
                            )}
                            {!isEditGroup && (
                              <Space>
                                <img
                                  src={images.admin.pen_alt_active}
                                  alt="pen"
                                  className="pointer"
                                  onClick={() => {
                                    setIsEditGroup(true)
                                    setIsAdd(false)
                                    setSelectedRecordGroup(item)
                                  }}
                                />
                                <img
                                  src={images.admin.trash_icon}
                                  alt="delete"
                                  className="pointer"
                                  onClick={handleDeleteGroup}
                                />
                              </Space>
                            )}

                            {isEditGroup &&
                            selectedRecordGroup?.id === item?.id ? (
                              <Space>
                                <img
                                  src={images.admin.save_icon}
                                  alt="save_icon"
                                  style={{ width: 16 }}
                                  className="pointer"
                                  onClick={() => handleUpdateGroup(item)}
                                />
                                <img
                                  src={images.admin.x_active}
                                  alt="close_icon"
                                  style={{ width: 16 }}
                                  className="pointer"
                                  onClick={() => {
                                    setSelectedRecordGroup(null)
                                    setIsEditGroup(false)
                                  }}
                                />
                              </Space>
                            ) : null}
                          </Space>
                        </div>
                      )
                    })}
                  <div>
                    {isAdd ? (
                      <Space>
                        <div style={{ width: '280px' }}>
                          <GroupSelect
                            multiple={false}
                            value={selectedGroup}
                            onChange={(values) => {
                              setSelectedGroup(values)
                            }}
                            data={folders}
                          />
                        </div>

                        <Space>
                          <img
                            src={images.admin.save_icon}
                            alt="save_icon"
                            style={{ width: 16 }}
                            className="pointer"
                            onClick={() => handleUpdateGroup()}
                          />
                          <img
                            src={images.admin.x_active}
                            alt="close_icon"
                            style={{ width: 16 }}
                            className="pointer"
                            onClick={() => {
                              setSelectedRecordGroup(null)
                              setIsAdd(false)
                            }}
                          />
                        </Space>
                      </Space>
                    ) : null}{' '}
                    <div
                      className="lessons__attachments__add"
                      onClick={() => setIsAdd(true)}
                      style={{ marginTop: 18 }}
                    >
                      <img src={images.admin.plus_normal} alt="" /> ADD NEW
                      GROUP
                    </div>
                  </div>
                </div>
              ) : null}

              {isAscendoDomain && (
                <div className="form__field">
                  <label htmlFor="email" className="form__label">
                    CLIENTS
                  </label>
                  <div className="profile__text">
                    {userClients
                      ?.map((item) => item?.client_id && item?.client?.name)
                      ?.join(', ')}
                  </div>
                </div>
              )}

              {isAdmin && (
                <>
                  <label className="form__label">IS ADMIN</label>
                  {userData && (
                    <div style={{ display: 'flex', marginBottom: 20 }}>
                      <Checkbox
                        onChange={handleCheckAdmin}
                        checked={formik.values.admin}
                      >
                        Admin
                      </Checkbox>

                      <Checkbox
                        onChange={handleCheckTrainer}
                        checked={formik.values.trainer}
                      >
                        Trainer
                      </Checkbox>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        ) : (
          <UserForm
            formik={formik}
            edit={edit}
            error={error}
            userData={userData}
            clients={clients}
            userClients={userClients}
            currentUserClients={currentUserClients}
            handleCheckAdmin={handleCheckAdmin}
            handleCheckTrainer={handleCheckTrainer}
            isAscendoDomain={isAscendoDomain}
            isAdmin={isAdmin}
            folders={folders}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        )}
        <Col md={12} xs={24}>
          {userID && (
            <CreateEnrollment listCourse={listCourse} user={userData} />
          )}
          {userID && isAscendoDomain && (
            <AssignClient
              clients={clients}
              user={userData}
              currentUserClients={currentUserClients}
              userClients={userClients}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          {userID && (
            <UserProfile
              domain={domain}
              dispatch={dispatch}
              userId={userID}
              userData={userData}
              userProfile={userProfile}
              isAdminUserData={isAdminUserData}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          {userID && (
            <SFCClaims
              userProfile={userProfile}
              isUpdateAllInvoices={isUpdateAllInvoices}
              setIsUpdateAllInvoices={setIsUpdateAllInvoices}
              courseAllInvoices={courseAllInvoices}
              setCourseAllInvoices={setCourseAllInvoices}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          {userData && (
            <Enrollment
              dispatch={dispatch}
              userProfile={userProfile}
              userData={userData}
              setIsUpdateAllInvoices={setIsUpdateAllInvoices}
              setCourseAllInvoices={setCourseAllInvoices}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default CreateUser
