import { Form, Modal, Popconfirm, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { getSchedules, getSchedulesSuccess } from '../../../../actions'
import { makeSelectSchedules } from '../../../../selectors'
import './styles.scss'
import axiosInstance from '../../../../../../utils/axiosInstance'
import { showSuccess } from '../../../../../../utils/notification'
import {
  hideGlobalLoading,
  showGlobalLoading,
} from '../../../../../Modal/actions'

const TransferQuizModal = ({ listCourses, visible, onCancel, userID }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [selectedCourse, setSelectCourse] = useState(null)
  const [currentScheduleID, setCurrentScheduleID] = useState(null)
  const schedules = useSelector(makeSelectSchedules())

  useEffect(() => {
    if (selectedCourse) {
      const isAdmin = true
      dispatch(getSchedules(selectedCourse, isAdmin, null, false, true, true))
    }
  }, [selectedCourse])

  const handleConfirm = async (values) => {
    try {
      dispatch(showGlobalLoading())
      const { new_schedule_id, old_schedule_id } = values
      if (!new_schedule_id || !old_schedule_id) {
        dispatch(hideGlobalLoading())
        return showSuccess('Please select current schedule and new schedule!')
      }

      const payload = { old_schedule_id, new_schedule_id }
      await axiosInstance.patch(
        `/api/lms/courses/answers/users/${userID}`,
        payload
      )

      dispatch(hideGlobalLoading())
      showSuccess('Quiz records transfer successful!')
      handleCancel()
    } catch (err) {
      dispatch(hideGlobalLoading())
      showSuccess('Quiz records transfer failed!')
    }
  }
  const handleCancel = () => {
    form.resetFields()
    dispatch(getSchedulesSuccess(null))
    setSelectCourse(null)
    setCurrentScheduleID(null)
    onCancel()
  }
  return (
    <Modal
      visible={visible}
      footer={null}
      width="40vw"
      maskClosable={false}
      destroyOnClose
      onCancel={handleCancel}
    >
      <div className="ld-modal-2">
        <h2 className="ld-modal__heading" style={{ fontWeight: 'bold' }}>
          Transfer Quiz Records
        </h2>
        <div>
          <Form layout="vertical" form={form} name="transferQuizForm">
            <Form.Item
              label={<span className="sc-modal__label">COURSES</span>}
              name="courseId"
              getValueFromEvent={(value) => {
                setSelectCourse(value)
                return value
              }}
            >
              <Select placeholder="Select a course">
                {listCourses &&
                  listCourses?.length > 0 &&
                  listCourses.map((course) => (
                    <Select.Option key={course.id} value={course.id}>
                      {course.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={<span className="sc-modal__label">Current Schedule</span>}
              name="old_schedule_id"
              getValueFromEvent={(value) => {
                setCurrentScheduleID(value)
                return value
              }}
            >
              <Select placeholder="Select current schedule">
                {schedules &&
                  schedules?.length > 0 &&
                  schedules.map((schedule) => {
                    const code = schedule?.class_code || ''
                    const startDate = schedule?.start_date
                    const endDate = schedule?.end_date
                    const label = schedule?.isAlwaysAvailable
                      ? `Always Available ${code}`
                      : `(${code}) ${dayjs(startDate).format(
                          'DD MMM'
                        )} - ${dayjs(endDate).format('DD MMM YYYY')}`
                    return (
                      <Select.Option key={schedule.id} value={schedule.id}>
                        {label}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<span className="sc-modal__label">New schedule</span>}
              name="new_schedule_id"
            >
              <Select placeholder="Select new schedule">
                {schedules &&
                  schedules?.length > 0 &&
                  schedules.map((schedule) => {
                    const code = schedule?.class_code || ''
                    const startDate = schedule?.start_date
                    const endDate = schedule?.end_date
                    const label = schedule?.isAlwaysAvailable
                      ? `Always Available ${code}`
                      : `(${code}) ${dayjs(startDate).format(
                          'DD MMM'
                        )} - ${dayjs(endDate).format('DD MMM YYYY')}`
                    return (
                      <Select.Option
                        key={schedule.id}
                        value={schedule.id}
                        disabled={schedule?.id === currentScheduleID}
                      >
                        {label}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <Popconfirm
          title="Please confirm transfer of quiz to new schedule"
          onConfirm={() => handleConfirm(form.getFieldsValue())}
          okText="Confirm"
          placement="topLeft"
          cancelText="Cancel"
          cancelButtonProps={{ style: { borderRadius: '4px' } }}
          okButtonProps={{
            style: {
              outline: 'none',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '4px',
              backgroundColor: '#00122a',
              color: '#fff',
              fontSize: '1.4rem',
              lineHeight: '15px',
              color: '#ffffff',
              whiteSpace: 'nowrap',
            },
          }}
        >
          <button className="btn--primary">Transfer</button>
        </Popconfirm>
      </div>
    </Modal>
  )
}

export default React.memo(TransferQuizModal)
