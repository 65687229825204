import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { get } from 'lodash'

import images from 'Themes/Images'
import Input from 'component/Input'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'
import InputTypePassword from 'component/InputTypePassword'
import ForgotPasswordBtn from 'component/ForgotPasswordBtn'
import { ERROR_MESSAGE } from 'utils/constants'
import { LoginSuccess } from 'container/Auth/actions'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import Alert from 'container/Checkout/components/Alert'
import { postEnroll } from 'container/Home/actions'
import * as actions from 'container/Auth/actions'
import { setAccessToken } from 'utils/request'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from '../../../../../Home/selectors'
import { DOMAIN } from '../../../../../../routes'

const UserSchema = Yup.object().shape({
  password: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().email().required(ERROR_MESSAGE),
})

const Login = ({
  course,
  history,
  schedule,
  dispatch,
  handleSwitchMode,
  requiredSchedule,
  isSingaporeSkillsAcademies,
  handlePaymentMethodFreeCourse,
  isUnicornDomain,
}) => {
  const domain = useSelector(makeSelectDomain())
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA
  const [errorMessage, setErrorMessage] = useState('')
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: UserSchema,
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      const body = {
        login: values.email,
        password: values.password,
      }
      const requestURL = `${config.baseUrl}/api/users/login`
      try {
        const res = await fetch(requestURL, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })

        const data = await res.json()
        const errors = get(data, 'errors', null)
        const result = get(data, 'data.result', null)

        const accessToken = get(data, 'data.access_token', '')
        if (errors) {
          dispatch(hideGlobalLoading())
          if (errors.code === 422) {
            return setErrorMessage(get(errors, 'errors.login[0]'))
          }
          if (errors.code === 400) {
            return setErrorMessage(get(errors, 'message'))
          }
          if (errors.code === 401) {
            return setErrorMessage(get(errors, 'message'))
          }
          return
        } else {
          setErrorMessage('')
          dispatch(LoginSuccess(result))
        }

        dispatch(actions.setAccessToken(accessToken))
        setAccessToken(accessToken)
        if (isSingaporeSkillsAcademies || wfaDomain) {
          const userID = get(result, 'uid')

          dispatch(showGlobalLoading())
          const setPayment = await handlePaymentMethodFreeCourse(userID)
          if (setPayment) {
            setTimeout(() => {
              dispatch(postEnroll({ is_send: true }))
              dispatch(hideGlobalLoading())
            }, 2000)
          }
          return
        }

        if (!requiredSchedule) {
          return history.push(
            `/checkout/${get(course, 'id', '')}/complete-profile`
          )
        } else {
          if (schedule) {
            history.push(
              `/checkout/${get(course, 'id', '')}/confirm?schedule=${schedule}`
            )
          } else {
            history.push(`/checkout/${get(course, 'id', '')}/confirm`)
          }
        }

        dispatch(hideGlobalLoading())
      } catch (err) {
        dispatch(hideGlobalLoading())
        console.log('err', err)
      }
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      {errorMessage && (
        <Alert
          type="error"
          message={errorMessage}
          style={{ marginBottom: 12 }}
        />
      )}
      <h3 className="hs-confirm__heading">Log in to Enroll</h3>
      <p className="hs-confirm__text">
        Enrolled before? Log into your account to enroll and get access to
        course materials and assessments.
      </p>
      <Input
        label="EMAIL ADDRESS"
        name="email"
        className="mg-small"
        placeHolder="Enter your email address"
        value={formik.values.email}
        formik={formik}
        onChange={(e) => {
          formik.setFieldValue(
            'email',
            e.currentTarget.value.trim().toLowerCase()
          )
        }}
      />
      <div className="form__field">
        <div className="form__box">
          <label htmlFor="password" className="form__label">
            YOUR PASSWORD
          </label>
          <ForgotPasswordBtn />
        </div>

        <InputTypePassword
          placeholder={'Enter your password'}
          onChange={(e) => {
            formik.setFieldValue('password', e.currentTarget.value.trim())
          }}
          name="password"
          className={`form__input ${
            formik.touched.password && formik.errors.password
              ? 'form__input__error'
              : ''
          }`}
          value={formik.values.password}
          style={{ width: '100%', padding: '8px 12px' }}
          styleIcon={{ top: -5 }}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="form__error">{formik.errors.password}</div>
        )}
      </div>
      <BtnPrimary
        name="Log in"
        style={{
          fontWeight: '800',
          padding: '12px 12px 12px 24px',
          fontSize: 12,
          width: '100%',
          marginTop: 15,
          marginBottom: 30,
        }}
        styleIcon={{ marginBottom: 0 }}
        icon={images.course.arrow_right_active}
      />
      {!isUnicornDomain && (
        <div className="auth__switch">
          First time enrolling?
          <span onClick={handleSwitchMode}> Register to Enroll</span>
        </div>
      )}
    </form>
  )
}

export default Login
